import React from "react";
import { Badge } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchLiveEquity, login } from "../../Api";
import { FYERS_LOGIN, LOADING } from "../../Constants";
import { AxiosInstance } from "../../miscellaneous";
import { setLiveEquities } from "../../slices/Live";
import { updateUser } from "../../slices/User";
import { updateLoading} from "../../slices/Loading";

function SignInPage() {

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      dispatch(updateLoading(LOADING))
      let res = await login(data);
      dispatch(updateUser(res.data));
      AxiosInstance.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.access;
      localStorage.setItem("auth", JSON.stringify(res.data));
      const min = 5
      setInterval(async () => {
        let live = (await fetchLiveEquity()).data
        dispatch(setLiveEquities(live))
      }, min * 60 * 1000)
      navigate("/");
      return () => { }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally{
      dispatch(updateLoading(""))
    }
  };

  return (<>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" controlId="userEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter email"
          {...register("username", { required: true })}
          isInvalid={!!errors.username}
        />
        <Form.Control.Feedback type="invalid">
          Username required!
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Password"
          {...register("password", { required: true, minLength: 8 })}
          isInvalid={!!errors.password}
        />
        <Form.Control.Feedback type="invalid">
          Password required!
        </Form.Control.Feedback>
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    <Link to={FYERS_LOGIN} className="block w-fit">
      <h1>
        <Badge bg="primary">Login to FYERS</Badge>
      </h1>
    </Link>
  </>
  );
}

export default SignInPage;

import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavigateToSignIn from "../NavigateToSignIn";
import { FYERS_LOGIN } from "../../Constants";

function Home() {
  return (
    <>
      <NavigateToSignIn />
      <h1 className="mb-8">Home</h1>
      {/* <Link to={"delivery-qty-table"}>
        <h1>
          <Badge bg="primary">Delivery Qty Table</Badge>
        </h1>
      </Link> */}
      <Link to={FYERS_LOGIN} className="block w-fit">
        <h1>
          <Badge bg="primary">Login to FYERS</Badge>
        </h1>
      </Link>
      <hr/>
      <Link to={"trades"} className="block w-fit">
        <h1>
          <Badge bg="primary">Trades</Badge>
        </h1>
      </Link>
      <Link to={"watch"} className="block w-fit">
        <h1>
          <Badge bg="primary">Watch</Badge>
        </h1>
      </Link>
      <Link to={"portfolio"} className="block w-fit">
        <h1>
          <Badge bg="primary">Portfolio</Badge>
        </h1>
      </Link>
      <Link to={"admin-actions"} className="block w-fit">
        <h1>
          <Badge bg="primary">Admin Actions</Badge>
        </h1>
      </Link>
    </>
  );
}

export default Home;

import { createSlice } from "@reduxjs/toolkit";

export const PortfolioSlice = createSlice({
    name: "portfolio",
    initialState: {
        portfolios: [],
    },
    reducers: {
        updatePortfolios: (state, action) => {
            state.portfolios = action.payload;
        }
    },
});

export const { updatePortfolios } = PortfolioSlice.actions;

export default PortfolioSlice.reducer;

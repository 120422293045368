import { createSlice } from "@reduxjs/toolkit";

export const LastCyclesSlice = createSlice({
    name: "lastCycles",
    initialState: { 
        lastCycles: {},
    },
    reducers: {
        updateCycles: (state, action) => {
            state.lastCycles = action.payload;
        },
    },
});

export const { updateCycles } = LastCyclesSlice.actions;

export default LastCyclesSlice.reducer;

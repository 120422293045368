import React from 'react';
import NavigateToSignIn from '../NavigateToSignIn';
import { Container, ListGroup } from 'react-bootstrap';

const Shortcuts = () => {
    const shortcuts = [
        { key: 'Ctrl + B', description: 'Go To Equity page based on symbol searched' },
        { key: 'Ctrl + I', description: 'Intra Calculator' },
        { key: 'Esc', description: 'Exit current action (in general)' },
        { key: 'Home', description: 'Scroll to start of a page' },
        { key: 'End', description: 'Scroll to end of a page' },
    ];

    return (
        <>
            <NavigateToSignIn />
            <Container>
                <h2>Keyboard Shortcuts</h2>
                <ListGroup>
                    {shortcuts.map((shortcut, index) => (
                        <ListGroup.Item key={index}>
                            <strong>{shortcut.key}:</strong> {shortcut.description}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Container>
        </>
    );
};

export default Shortcuts;
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Stack,
  Table,
  ToggleButton
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import { FaExternalLinkAlt } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { fetchAllAccountEquityList, fetchAllPortfolio, fetchNetValue } from "../../Api";
import { BroadIndex, DAY, DIMPULSE, IMPULSE, LOADING, NORMAL, PROFIT, SIMPLUSE, colorMap } from "../../Constants";
import { updateLoading } from "../../slices/Loading";
import { updatePortfolios } from "../../slices/Portfolio";
import DefaultColumnFilter from "../Filters/DefaultColumnFilter";
import SelectColumnFilter from "../Filters/SelectColumnFilter";
import EquityTradeModal from "../Modals/EquityTradeModal";
import NavigateToSignIn from "../NavigateToSignIn";

function PortfolioPage() {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const accounts = useSelector((state) => state.account.accounts);
  const tags = useSelector((state) => state.tags.tags);
  const user = useSelector((state) => state.user);
  const liveEquities = useSelector((state) => state.live.equities);
  const accountsSafe = accounts ? accounts : [];
  const tagsSafe = tags ? tags : [];
  const portfolios = useSelector((state) => state.portfolio.portfolios);
  const [activeTag, setactiveTag] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  // redux
  const [accountEquity, setAccountEquity] = useState([]);
  // redux
  const [netCash, setNetCash] = useState(0);
  const uniqueEquities = useMemo(
    () => liveEquities ? Object.keys(liveEquities).sort() : [],
    [liveEquities]
  );
  const accountsMap = useMemo(
    () =>
      accounts.reduce((state, ele) => {
        state[ele.id] = ele.client;
        return state;
      }, {}),
    [accounts]
  );
  const accountEquityMap = useMemo(() => {
    return accountEquity.reduce((state, ele) => {
      if (ele.symbol in state) {
        state[ele.symbol][accountsMap[ele.account]] = ele.quantity
      } else {
        state[ele.symbol] = { [accountsMap[ele.account]]: ele.quantity }
      }
      return state
    }, {})
  }, [accountEquity, accountsMap])
  const tagsMap = useMemo(
    () =>
      tags.reduce((state, ele) => {
        state[ele.id] = ele.name;
        return state;
      }, {}),
    [tags]
  );
  useEffect(() => {
    if (user?.data?.access || refresh) {
      (async () => {
        try {
          dispatch(updateLoading(LOADING))
          let portfolioData = (await fetchAllPortfolio())?.data;
          if (portfolioData) {
            dispatch(updatePortfolios(portfolioData));
          }
          let accountEquityData = (await fetchAllAccountEquityList())?.data
          if (accountEquityData) setAccountEquity(accountEquityData);
          let net_cash = (await fetchNetValue())?.data
          if (net_cash) setNetCash(net_cash)
          setRefresh(false);
        } catch (err) {
          toast.error(`Error: ${err.message}`);
        } finally {
          dispatch(updateLoading(""))
        }
      })();
    }
  }, [user, refresh, dispatch]);
  const data = useMemo(() => {
    let tempPortfolio = {};
    let tagsCheck = activeTag === "" ? false : true;

    JSON.parse(JSON.stringify(portfolios)).forEach((p) => {
      if (tagsCheck ? activeTag === p.tags : true) {
        let key = `${p.symbol}-${tagsCheck ? p.tags.id : ""
          }`;

        if (key in tempPortfolio) {
          let t = tempPortfolio[key];
          t.average = (
            (t.average * t.quantity + p.average * p.quantity) /
            (t.quantity + p.quantity)
          )?.toFixed(2);
          t.quantity += p.quantity;
        } else {
          tempPortfolio[key] = p;
        }
      }
    });

    let processedPortfolio = [];
    for (const k in tempPortfolio) {
      processedPortfolio.push(tempPortfolio[k]);
    }

    let tempData = processedPortfolio?.map((portfolioItem) => {
      let equity = liveEquities[portfolioItem.symbol]
      let avg = Number(portfolioItem.average);
      let ltp = Number(equity?.ltp)
      portfolioItem.broad_index = BroadIndex[equity?.broad_index]
      portfolioItem.amount = Number(portfolioItem.quantity * ltp)?.toFixed(2)
      portfolioItem["ltp"] = ltp?.toFixed(2);
      portfolioItem["avg_diff"] = (
        ((equity?.ltp - avg) * 100) /
        avg
      )?.toFixed(2);
      portfolioItem["tags"] = {
        id: portfolioItem.tags,
        display: tagsMap[portfolioItem.tags],
      };
      portfolioItem["account"] = {
        id: portfolioItem.account,
        display: accountsMap[portfolioItem.account],
      };

      let day_link = equity?.links?.find((link) => link.name === DAY)?.link
      let impulse_link = equity?.links?.find((link) => link.name === IMPULSE)?.link
      let dimpulse_link = equity?.links?.find((link) => link.name === DIMPULSE)?.link
      let simpluse_link = equity?.links?.find((link) => link.name === SIMPLUSE)?.link
      if (day_link) portfolioItem[DAY] = <a target="_blank" rel="noopener noreferrer" href={day_link}><FaExternalLinkAlt /></a>
      if (impulse_link) portfolioItem[IMPULSE] = <a target="_blank" rel="noopener noreferrer" href={impulse_link}><FaExternalLinkAlt /></a>
      if (dimpulse_link) portfolioItem[DIMPULSE] = <a target="_blank" rel="noopener noreferrer" href={dimpulse_link}><FaExternalLinkAlt /></a>
      if (simpluse_link) portfolioItem[SIMPLUSE] = <a target="_blank" rel="noopener noreferrer" href={simpluse_link}><FaExternalLinkAlt /></a>
      return portfolioItem;
    });
    tempData.forEach((row) => {
      accounts.forEach((account) => {
        row[account.client] = accountEquityMap?.[row.symbol]?.[account.client] || 0
      })
    })
    tempData.forEach((row) => row.average = Number(row.average)?.toFixed(2))
    tempData.forEach((row) => row.inv = (Number(row.average) * Number(row.quantity))?.toFixed(2))
    return tempData
    // ske later add "liveEquities" as dep after upgrading to TS and "onColumnFiltersChange"
    // eslint-disable-next-line
  }, [portfolios, accountsMap, tagsMap, activeTag, accountEquityMap, accounts]);

  const columns = useMemo(() => {
    let cols = [
      {
        Header: "D",
        accessor: DAY,
        disableFilters: true,
      }, {
        Header: "I",
        accessor: IMPULSE,
        disableFilters: true,
      }, {
        Header: "DI",
        accessor: DIMPULSE,
        disableFilters: true,
      }, {
        Header: "SI",
        accessor: SIMPLUSE,
        disableFilters: true,
      }, {
        Header: "Symbol",
        accessor: "symbol",
        filter: "like",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Index",
        accessor: "broad_index",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
      },
      {
        Header: "Average",
        accessor: "average",
        disableFilters: true,
      },
      {
        Header: "InvAmt",
        accessor: "inv",
        disableFilters: true,
      },
      {
        Header: "Market",
        accessor: "amount",
        disableFilters: true,
      },
      {
        Header: "Account",
        accessor: "account",
        disableFilters: true,
      },
      {
        Header: "Tag",
        accessor: "tags",
        disableFilters: true,
      },
      {
        Header: "buy_per",
        accessor: "buy_per",
        disableFilters: true,
      },
      {
        Header: "sell_per",
        accessor: "sell_per",
        disableFilters: true,
      },
      {
        Header: "LTP",
        accessor: "ltp",
        disableFilters: true,
      },
      {
        Header: "Avg ~ %",
        accessor: "avg_diff",
        disableFilters: true,
      },
      // {
      //   Header: "Add",
      //   accessor: "add",
      // },
    ]
    if (accounts) {
      accounts.forEach((account) => cols.push({
        Header: account.client,
        accessor: account.client,
        disableFilters: true,
      }))
    }
    return cols;
  }, [accounts]);
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // ske later
  // const [columnFilters, setColumnFilters] = useState([]) 

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy: [
          {
            id: 'symbol',
            desc: false
          }
        ],
        // columnFilters,
      },
      // ske later
      // onColumnFiltersChange: setColumnFilters,
    },
    useFilters,
    useSortBy
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns,
  } = tableInstance;
  // filtering data  
  const csvData = rows.map((row) => {
    let val = row.values
    let resRow = []
    columns.forEach((col) => {
      if (!["account", "tags", "buy_per", "sell_per"].includes(col.accessor)) {
        resRow.push(val[col.accessor])
      }
    })
    return resRow;
  })
  const headers = useMemo(() => columns.filter((col) => !["account", "tags", "buy_per", "sell_per"].includes(col.accessor)).map((col) => col.Header), [columns])
  useEffect(() => {
    setHiddenColumns(["account", "tags", "buy_per", "sell_per"]);
  }, [setHiddenColumns]);

  let unrealizedValue = useMemo(() => {
    let value = 0
    rows.forEach(row => {
      let ltp = Number(row?.values?.ltp)
      let qty = row?.values?.quantity
      if (ltp && qty) value += ltp * qty
    });
    return value
  }, [rows])
  let invested = 0
  if (activeTag) {
    if (tagsMap[activeTag] === NORMAL) {
      invested = netCash['net_cash_open_cycle']
    } else if (tagsMap[activeTag] === PROFIT) {
      invested = netCash['net_cash_closed_cycle']
    }
  } else {
    invested = netCash['net_cash_open_cycle'] + netCash['net_cash_closed_cycle']
  }
  return (
    <Container>
      <NavigateToSignIn />
      <EquityTradeModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setRefresh={setRefresh}
        uniqueEquities={uniqueEquities}
        accounts={accountsSafe}
        tags={tagsSafe}
        row={currentRow}
      />
      <div className="mb-2">
        <h1 className="inline">Portfolio</h1>
      </div>
      <div className="flex justify-between">
        <h3>Unrealized : &nbsp; {Number(unrealizedValue)?.toFixed(2)}</h3>
        <h3>Invested  : &nbsp; {Number(invested?.toFixed(2))}</h3>
        <h3>Net  : &nbsp; {(unrealizedValue + invested)?.toFixed(2)}</h3>
      </div>
      <Stack direction="horizontal">
        {/* <ButtonGroup>
          <ToggleButton
            id={`account-all`}
            type="radio"
            variant={"outline-success"}
            name="account-radio"
            value={""}
            checked={activeAccount === ""}
            onChange={() => {
              setactiveAccount("");
            }}
          >
            All Banks
          </ToggleButton>
          {accountsSafe.map((radio) => (
            <ToggleButton
              id={`account-${radio.id}`}
              key={radio.id}
              type="radio"
              variant={"outline-success"}
              name="account-radio"
              value={radio.id}
              checked={activeAccount === radio.id}
              onChange={(e) => {
                setactiveAccount(radio.id);
              }}
            >
              {radio.client}
            </ToggleButton>
          ))}
        </ButtonGroup> */}
        <Button>
          <CSVLink data={csvData} headers={headers} filename={"Portfolio.csv"} className="text-white no-underline">
            Export to CSV
          </CSVLink>
        </Button>
        <ButtonGroup className="ml-auto">
          <ToggleButton
            id={`tag-all`}
            type="radio"
            variant={"outline-success"}
            name="tag-radio"
            value={""}
            checked={activeTag === ""}
            onChange={() => {
              setactiveTag("");
            }}
          >
            All Tags
          </ToggleButton>
          {tagsSafe.map((radio) => (
            <ToggleButton
              id={`tag-${radio.id}`}
              key={radio.id}
              type="radio"
              variant={"outline-success"}
              name="tag-radio"
              value={radio.id}
              checked={activeTag === radio.id}
              onChange={(e) => {
                setactiveTag(radio.id);
              }}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Stack>
      <Table
        bordered
        hover
        className="mt-3"
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span className="ml-3">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                  <div>
                    {column.canFilter ? column.render("Filter") : <></>}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  let equityColorCode
                  let colorClass
                  if (liveEquities[row.values.symbol]) {
                    equityColorCode = liveEquities[row.values.symbol].color
                    colorClass = equityColorCode ? colorMap[equityColorCode] : ""
                  }
                  return (
                    <td {...cell.getCellProps()} className={colorClass}>
                      {cell.render((renderRow) => {
                        if (renderRow.column.id === "avg_diff") {
                          return (
                            <div
                              className={
                                renderRow.value > 0
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }
                            >
                              {renderRow.value}
                            </div>
                          );
                        } else if (renderRow.column.id === "add") {
                          return (
                            <div
                              onClick={() => {
                                setCurrentRow(renderRow.row.values);
                                setModalShow(true);
                              }}
                            >
                              <GrAddCircle size={24} />
                            </div>
                          );
                        } else if (renderRow.column.id === "symbol") {
                          return (
                            <Link to={`/equities/${renderRow.value}`}>
                              {renderRow.value}
                            </Link>
                          );
                        } else if (["tags", "account"].includes(renderRow.column.id)) {
                          return <>{renderRow.value.display}</>
                        }
                        return <>{renderRow.value}</>;
                      })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}

export default PortfolioPage;

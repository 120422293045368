import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/Account";
import userReducer from "./slices/User";
import liveReducer from "./slices/Live";
import LoadingReducer from "./slices/Loading";
import allSymbolsReducer from "./slices/AllSymbols"
import TagsReducer from "./slices/Tags";
import LastCyclesReducer from "./slices/LastCycles";
import PortfolioReducer from "./slices/Portfolio";
import TradesPageDataReducer from "./slices/TradesPageData";
export default configureStore({
  reducer: {
    account: accountReducer,
    user: userReducer,
    live: liveReducer,
    allSymbols: allSymbolsReducer,
    tags: TagsReducer,
    lastCycles: LastCyclesReducer,
    loading: LoadingReducer,
    portfolio: PortfolioReducer,
    tradesPageData: TradesPageDataReducer
  },
});

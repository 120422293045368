import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import { fetchLastCycles, fetchTodayTrades, fetchTradesBetween } from "../../Api";
import { Action, BroadIndex, LOADING, TIMESTAMP_FORMAT, TRADE_NORMAL, TRADE_UPDATE, colorMap, productMap } from "../../Constants";
import { updateCycles } from "../../slices/LastCycles";
import { updateLoading } from "../../slices/Loading";
import { updatetradesPageData } from "../../slices/TradesPageData";
import DateFilter from "../Filters/DateFilter";
import DefaultColumnFilter from "../Filters/DefaultColumnFilter";
import SelectColumnFilter from "../Filters/SelectColumnFilter";
import { accountFilterFunc, dateFilterFunc, tagFilterFunc } from "../Filters/filters";
import EquityTradeModal from "../Modals/EquityTradeModal";
import NavigateToSignIn from "../NavigateToSignIn";

function TradesPage() {
  const dispatch = useDispatch();
  const [lastCycle, setLastCycle] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [mode, setMode] = useState(TRADE_NORMAL);
  const [currentRow, setCurrentRow] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const liveEquities = useSelector((state) => state.live.equities);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const allSymbols = useSelector((state) => state.allSymbols.allSymbols);
  const tags = useSelector((state) => state.tags.tags);
  const user = useSelector((state) => state.user);
  const accounts = useSelector((state) => state.account.accounts);
  const tradesPageData = useSelector((state) => state.tradesPageData.tradesPageData);
  const tagsMap = useMemo(
    () =>
      tags.reduce((state, ele) => {
        state[ele.id] = ele.name;
        return state;
      }, {}),
    [tags]
  );
  const accountsSafe = accounts ? accounts : [];
  const tagsSafe = tags ? tags : [];

  useEffect(() => {
    if (refresh && user?.data?.access) {
      (async () => {
        try {
          dispatch(updateLoading(LOADING))
          let allTradesData = (await fetchTodayTrades()).data
          if (allTradesData) dispatch(updatetradesPageData(allTradesData));
          let lastCyclesData = (await fetchLastCycles()).data
          if (lastCyclesData) dispatch(updateCycles(lastCyclesData));
        } catch (err) {
          toast.error(`Error: ${err.message}`);
        } finally {
          dispatch(updateLoading(""))
        }
      })();
      setRefresh(false);
    }
  }, [user, refresh, dispatch]);

  const data = React.useMemo(() => {
    if (tradesPageData.length === 0) return [];
    let accountsMap = accounts.reduce((res, cur) => {
      res[cur.id] = cur.client;
      return res;
    }, {});
    let tempData = JSON.parse(JSON.stringify(tradesPageData));
    if (lastCycle) {
      let lastCycleNumber = {}
      tempData.forEach((row) => {
        if ((lastCycleNumber[row.symbol] || -Infinity) < row.cycle) {
          lastCycleNumber[row.symbol] = row.cycle
        }
      })
      tempData = tempData.filter((row) => lastCycleNumber[row.symbol] === row.cycle)
    }
    for (const row of tempData) {
      row.broad_index = BroadIndex[liveEquities[row.symbol]?.broad_index]
      row.executed_ts = moment(row.executed_ts).format(TIMESTAMP_FORMAT);
      row.action = row.action === Action.BUY ? "BUY" : "SELL";
      let tag = Number(row.tags);
      row.tags = {
        id: tag,
        display: tagsMap[tag],
      };
      let acc = Number(row.account);
      row.account = {
        id: acc,
        display: accountsMap[acc],
      };
      row.product = productMap[row.product]
      if (row.action === "BUY") row.amount = (Number(row.price) * Number(row.quantity) + Number(row.brokerage))?.toFixed(2)
      else row.amount = (Number(row.price) * Number(row.quantity) - Number(row.brokerage))?.toFixed(2)
    }
    return tempData;
  }, [tradesPageData, accounts, tagsMap, lastCycle, liveEquities]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Cycle",
        accessor: "cycle", // accessor is the "key" in the data
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Index",
        accessor: "broad_index", // accessor is the "key" in the data
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Symbol",
        accessor: "symbol", // accessor is the "key" in the data
        filter: "like",
        Filter: DefaultColumnFilter,
      },
      {
        Header: "Timestamp",
        accessor: "executed_ts",
        Filter: DateFilter,
        filter: "dateFilterFunc",
        sortType: (rowA, rowB, columnId) => {
          let a = moment(rowA.original[columnId], "YYYY/MM/DD, HH:mm:ss a");
          let b = moment(rowB.original[columnId], "YYYY/MM/DD, HH:mm:ss a");
          let order = a > b ? 1 : -1;
          return order;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        disableFilters: true,
      },
      {
        Header: "Price",
        accessor: "price",
        disableFilters: true,
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableFilters: true,
      },
      {
        Header: "Brokerage",
        accessor: "brokerage",
        disableFilters: true,
      },
      {
        Header: "Account",
        accessor: "account",
        Filter: SelectColumnFilter,
        filter: "accountFilterFunc",
      },
      {
        Header: "Tag",
        accessor: "tags",
        Filter: SelectColumnFilter,
        filter: "tagFilterFunc",
      },
      {
        Header: "Product",
        accessor: "product",
        Filter: SelectColumnFilter,
      },
      {
        Header: "Comment",
        accessor: "comment",
        disableFilters: true,
      },
    ],
    []
  );
  const filterTypes = useMemo(
    () => ({
      dateFilterFunc: dateFilterFunc,
      accountFilterFunc: accountFilterFunc,
      tagFilterFunc: tagFilterFunc
    }),
    []
  );
  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'executed_ts',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  const amount = useMemo(() => {
    let sum = 0
    rows.forEach(row => {
      sum += row.values.action === "BUY" ? -Number(row.values.amount) : Number(row.values.amount)
    })
    return sum
  }, [rows])
  const handleFetchTradesBetween = async (e) => {
    e.preventDefault()
    try {
      dispatch(updateLoading(LOADING))
      let tradesBetween = (await fetchTradesBetween({ startDate, endDate })).data
      if (tradesBetween.length === 0) toast.warn("No trades in given range")
        dispatch(updatetradesPageData(tradesBetween))
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  }
  return (
    <>
      <NavigateToSignIn />
      <EquityTradeModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        setRefresh={setRefresh}
        uniqueEquities={allSymbols}
        accounts={accountsSafe}
        tags={tagsSafe}
        row={currentRow}
        mode={mode}
      />
      <div className="mb-2">
        <h1 className="inline">{lastCycle ? "Last Cycle" : "All"} Trades</h1>
        <span className="float-right">
          <Button className="mr-3" onClick={() => setLastCycle(!lastCycle)}>
            Show {!lastCycle ? "Last Cycle" : "All Trades"}
          </Button>
          <Button
            onClick={() => {
              setCurrentRow({});
              setModalShow(true);
              setMode(TRADE_NORMAL);
            }}
          >
            Add
          </Button>
        </span>
      </div>
      {rows.length === 0 && <h2 className="mx-auto w-fit text-gray-700	">No Trades</h2>}
      {rows.length > 0 && <Table responsive {...getTableProps()}>
        <thead>
          {
            headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  headerGroup.headers.map((column) => (
                    <th
                      className="min-w-fit"
                    >
                      <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {
                          column.render("Header")
                        }
                      </span>
                      <span className="ml-3">
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map((row) => {
              prepareRow(row);
              let equityColorCode
              let colorClass
              try {
                equityColorCode = liveEquities[row.values.symbol]?.color
                colorClass = equityColorCode ? colorMap[equityColorCode] : ""
              } catch (err) {
                toast.error(err)
              }
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    let rowData = JSON.parse(JSON.stringify(row.values));
                    rowData.id = row.original.id;
                    rowData.symbol = [rowData.symbol]
                    rowData.timestamp = moment(
                      rowData.executed_ts,
                      "YYYY/MM/DD, HH:mm:ss a"
                    );
                    rowData.action = rowData.action === "BUY" ? Action.BUY : Action.SELL
                    setCurrentRow(rowData);
                    setModalShow(true);
                    setMode(TRADE_UPDATE);
                  }}
                >
                  {
                    row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className={colorClass}>
                          {
                            cell.render((cell) => {
                              if (cell.column.id === "tags")
                                return <>{cell.value.display}</>;
                              if (cell.column.id === "account")
                                return <>{cell.value.display}</>;
                              if (cell.column.id === "symbol")
                                return <Link to={`/equities/${cell.value}`}>
                                  {cell.value}
                                </Link>;
                              return <>{cell.value}</>;
                            })
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </Table>}
      <div className="flex">
        <Form inline className="justify-content-center">
          <Row className="align-items-center">
            <Col xs="auto">
              <FloatingLabel
                controlId="startDate"
                label="Start Date"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FloatingLabel>
            </Col>

            <Col xs="auto">
              <FloatingLabel
                controlId="endDate"
                label="End Date"
                className="mb-3"
              >
                <Form.Control
                  type="date"
                  placeholder="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FloatingLabel>
            </Col>

            <Col xs="auto">
              <Button className="mb-2" onClick={handleFetchTradesBetween}>
                Fetch Trades
              </Button>
            </Col>
          </Row>
        </Form>
        <h2 className="ml-auto">Amount: {amount?.toFixed(2)}</h2>
      </div>
    </>
  );
}

export default TradesPage;

import { createSlice } from "@reduxjs/toolkit";

export const LoadingSlice = createSlice({
    name: "loading",
    initialState: {
        msg: ""
    },
    reducers: {
        updateLoading: (state, action) => {
            state.msg = action.payload
        }
    },
});

export const { updateLoading } = LoadingSlice.actions;

export default LoadingSlice.reducer;

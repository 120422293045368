import { createSlice } from "@reduxjs/toolkit";

export const TradesPageDataSlice = createSlice({
    name: "tradesPageData",
    initialState: {
        tradesPageData: []
    },
    reducers: {
        updatetradesPageData: (state, action) => {
          state.tradesPageData = action.payload;
        },
    },
});

export const { updatetradesPageData } = TradesPageDataSlice.actions;

export default TradesPageDataSlice.reducer;

import { MdDelete } from "react-icons/md";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchLiveEquity, updateLinks } from '../../Api';
import { LINK_KEY_NAME, LINK_KEY_VALUE, LOADING } from '../../Constants';
import { updateLoading } from '../../slices/Loading';
import { setLiveEquities } from "../../slices/Live";

const LinksModal = ({ show, setShow, symbol, equityLinks }) => {
    const dispatch = useDispatch();
    const [links, setLinks] = useState([])

    const [validateNow, setValidateNow] = useState(false)
    const handleRemove = (index) => {
        let list = JSON.parse(JSON.stringify(links))
        list.splice(index, 1)
        setLinks(list)
    }

    useEffect(() => {
        if (equityLinks?.length > 0) {
            setLinks(equityLinks)
        }
        else {
            setLinks([
                { name: "Day", link: "" },
                { name: "Impulse", link: "" },
                { name: "DImpulse", link: "" },
                { name: "SImpluse", link: "" },
            ])
        }
    }, [symbol, equityLinks])
    const handleSubmit = async () => {
        setValidateNow(true)
        for (let index = 0; index < links.length; index++) {
            const element = links[index];
            if (!element?.name) {
                return
            }
            if (!element?.link) {
                return
            }
        }
        try {
            dispatch(updateLoading(LOADING));
            setShow(false)
            let res = (await updateLinks({ symbol, payload: links })).data;
            toast.success(res?.detail);
            let liveEq = (await fetchLiveEquity()).data
            if (liveEq) dispatch(setLiveEquities(liveEq));
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            dispatch(updateLoading(''));
        }
    }
    const handleAdd = () => {
        let list = JSON.parse(JSON.stringify(links))
        list.push({ name: "", link: "" })
        setLinks(list)
    }
    const handleListChange = (key, index, value) => {
        if (key === LINK_KEY_NAME) {
            let list = JSON.parse(JSON.stringify(links))
            list[index].name = value
            setLinks(list)
        } else if (key === LINK_KEY_VALUE) {
            let list = JSON.parse(JSON.stringify(links))
            list[index].link = value
            setLinks(list)
        }
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modify Links
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {links?.map((item, index) =>
                    <InputGroup className="mb-3">
                        <Form.Control
                            value={item.name}
                            onChange={(e) => handleListChange(LINK_KEY_NAME, index, e.target.value)}
                            isInvalid={validateNow ? !item?.name : false}
                            aria-label="Name"
                            placeholder='NSE'
                        />
                        <Form.Control
                            value={item.link}
                            onChange={(e) => handleListChange(LINK_KEY_VALUE, index, e.target.value)}
                            isInvalid={validateNow ? !item?.link : false}
                            aria-label="link"
                            placeholder='https://www.nseindia.com/'
                        />
                        <Badge bg="danger" onClick={() => handleRemove(index)}>
                            <MdDelete size={20} />
                        </Badge>  &nbsp;
                    </InputGroup>
                )}

                <div className=''>
                    <Button variant="primary" className="w-full ml-auto" onClick={handleAdd}>
                        Add
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="ml-auto" onClick={handleSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LinksModal;
import { createSlice } from "@reduxjs/toolkit";

export const TagsSlice = createSlice({
    name: "tags",
    initialState: {
        tags: [],
    },
    reducers: {
        updateTags: (state, action) => {
            state.tags = action.payload
        }
    },
});

export const { updateTags } = TagsSlice.actions;

export default TagsSlice.reducer;

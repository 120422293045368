import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import DeliveryQtyTable from "./components/DeliveryQtyTable";
import NavBar from "./components/NavBar";
import EquityPage from "./components/Pages/EquityPage";
import FyersRedirectPage from "./components/Pages/FyersRedirectPage";
import Home from "./components/Pages/Home";
import PortfolioPage from "./components/Pages/PortfolioPage";
import Shortcuts from "./components/Pages/Shortcuts";
import SignInPage from "./components/Pages/SignInPage";
import TradesPage from "./components/Pages/TradesPage";
import WatchPage from "./components/Pages/WatchPage";
import AdminActionsPage from "./components/Pages/AdminActionsPage";
import ExportPage from "./components/Pages/ExportPage";

function App() {
  const loadingMsg = useSelector((state) => state.loading.msg);
  
  return (
    <>
      <div className={`loading-container ${loadingMsg ? 'flex' : 'hidden'}`}>
        <div className="spinner-container">
          <Spinner animation="border" role="status" />
          <span className="loading-text">{loadingMsg}</span>
        </div>
      </div>
      <NavBar />
      <Container className="mt-20">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/delivery-qty-table" element={<DeliveryQtyTable />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
          <Route path="/trades" element={<TradesPage />} />
          <Route path="/watch" element={<WatchPage />} />
          <Route path="/equities/:symbol" element={<EquityPage />} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/export-import" element={<ExportPage />} />
          <Route path="/fyers-redirect-uri" element={<FyersRedirectPage />} />
          <Route path="/shortcuts" element={<Shortcuts />} />
          <Route path="/admin-actions" element={<AdminActionsPage />} />
        </Routes>
      </Container>
      <ToastContainer />
    </>
  );
}

export default App;

import momentTimezone from "moment-timezone";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Table
} from "react-bootstrap";
import { AiFillGold } from "react-icons/ai";
import { BiRupee } from "react-icons/bi";
import { HiMinus, HiPlus } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useFilters, useSortBy, useTable } from "react-table";
import { toast } from "react-toastify";
import {
  closeCycle,
  fetchCompanyAccounts,
  fetchLastCycles,
  fetchLiveEquity,
  fetchPortfolio,
  fetchTrades,
  fetchWatch,
  updateColor,
  updatePortfolioWatchs
} from "../../Api";
import { Action, BroadIndex, CONFIRMATION_MODAL, LINKS_MODAL, LOADING, NORMAL, PROFIT, TIMESTAMP_FORMAT, WATCH_MODAL, productMap } from "../../Constants";
import { updateCycles } from "../../slices/LastCycles";
import { setLiveEquities } from "../../slices/Live";
import { updateLoading } from "../../slices/Loading";
import DateFilter from "../Filters/DateFilter";
import DefaultColumnFilter from "../Filters/DefaultColumnFilter";
import SelectColumnFilter from "../Filters/SelectColumnFilter";
import { dateFilterFunc } from "../Filters/filters";
import ConfirmationModal from "../Modals/ConfirmationModal";
import WatchTemplateModal from "../Modals/WatchTemplateModal";
import NavigateToSignIn from "../NavigateToSignIn";
import LinksModal from "../Modals/LinksModal";
const Change = Object.freeze({
  PRICE: "P",
  QUANTITY: "Q",
});

function EquityPage() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { symbol } = useParams();
  const [lastCycle, setLastCycle] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [accountStocks, setAccountStocks] = useState([]);
  const [trades, setTrades] = useState([]);
  const [watch, setWatch] = useState([]);
  const accounts = useSelector((state) => state.account.accounts);
  const tags = useSelector((state) => state.tags.tags);
  const lastCycles = useSelector((state) => state.lastCycles.lastCycles);
  const [watchPriceData, setWatchPriceData] = useState(undefined);
  // todo can change this to useMemo
  const [currentTag, setCurrentTag] = useState(false)
  const accountsMap = useMemo(
    () =>
      accounts.reduce((state, ele) => {
        state[ele.id] = ele;
        return state;
      }, {}),
    [accounts]
  );
  const tagsMap = useMemo(
    () =>
      tags.reduce((state, ele) => {
        state[ele.id] = ele;
        return state;
      }, {}),
    [tags]
  );
  const openWatchModal = (tag) => {
    setShowModal(WATCH_MODAL)
    setCurrentTag(tag)
  }
  const tagPortfolioDataMap = useMemo(() => {
    let tempPortfolio = {};
    let key = 'tags'
    portfolio?.portfolios?.forEach((p) => {
      if (p[key] in tempPortfolio) {
        let t = tempPortfolio[p[key]];
        if (t.action === Action.BUY) {
          t.average = (
            (t.average * t.quantity + p.average * p.quantity) /
            (t.quantity + p.quantity)
          ).toFixed(2);
        }
        t.quantity += p.quantity;
      } else {
        tempPortfolio[p[key]] = {
          average: Number(p.average).toFixed(2),
          quantity: p.quantity,
          [key]: p[key]
        };
      }
    });
    return tempPortfolio;
  }, [portfolio]);
  const liveEquities = useSelector((state) => state.live.equities);
  const equity = liveEquities[symbol]

  const handleColorChange = async (e) => {
    let changedColor = e.target.value
    try {
      dispatch(updateLoading(LOADING))
      let data = (await updateColor({ symbol, color: changedColor })).data
      if (data?.detail === "success") {
        toast.success("Successfully Updated!")
        let liveEq = (await fetchLiveEquity()).data
        if (liveEq) dispatch(setLiveEquities((liveEq)));
      }
      else {
        toast.error("Update Failed!")
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  }
  const data = useMemo(() => {
    return trades.filter((trade) => {
      if (lastCycle) return trade.cycle === lastCycles[symbol]?.cycle
      return true
    }).map((trade) => {
      trade.price = Number(trade.price).toFixed(2);
      if (typeof (trade.account) !== 'object') trade.account = accountsMap[trade.account];
      if (typeof (trade.tags) !== 'object') trade.tags = tagsMap[trade.tags];
      trade.executed_ts = moment(trade.executed_ts).format(TIMESTAMP_FORMAT);
      if (trade.action === Action.BUY) {
        trade.amount = (Number(trade.price) * Number(trade.quantity) + Number(trade.brokerage))?.toFixed(2)
      } else if (trade.action === Action.SELL) {
        trade.amount = Number(trade.price) * Number(trade.quantity) - Number(trade.brokerage)?.toFixed(2)
      }
      if (trade.product.length === 1) trade.product = productMap[trade.product]
      return trade;
    });
  }, [trades, tagsMap, accountsMap, lastCycle, lastCycles, symbol]);
  const filterTypes = useMemo(
    () => ({
      accountFilter: (rows, columnIds, filterValue) => {
        return rows.filter((row) => {
          // ;
          return row.values.account.client === filterValue;
        });
      },
      tagFilter: (rows, columnIds, filterValue) => {
        return rows.filter((row) => {
          return row.values[columnIds[0]].name === filterValue;
        });
      },
      dateFilterFunc: dateFilterFunc
    }),
    []
  );
  const columns = useMemo(() => {
    return [
      {
        Header: "Cycle",
        accessor: "cycle",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Account",
        accessor: "account",
        Filter: SelectColumnFilter,
        filter: "accountFilter",
      },
      {
        Header: "Timestamp",
        accessor: "executed_ts",
        Filter: DateFilter,
        filter: "dateFilterFunc",
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Brokerage",
        accessor: "brokerage",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Tag",
        accessor: "tags",
        Filter: SelectColumnFilter,
        filter: "tagFilter",
      },
      {
        Header: "Product",
        accessor: "product",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Comment",
        accessor: "comment",
        disableFilters: true,
      },
    ];
  }, []);
  const handleCloseCycle = () => {
    setShowModal(CONFIRMATION_MODAL)
  }
  const runCloseCycle = async () => {
    try {
      dispatch(updateLoading(LOADING))
      let res = (await closeCycle(symbol)).data
      if (res) {
        toast.success(res.message)
        refreshEquityData()
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  }
  const handleWatchSave = async () => {
    for (const key in watchPriceData) {
      let ifBreak = false;
      let tagData = watchPriceData[key];
      for (const watch of tagData.buyList) {
        if (Number(watch.price) <= 0 || Number(watch.quantity) <= 0) {
          toast.error("Please modify invalid data");
          ifBreak = true;
          break;
        }
      }
      if (ifBreak) break;
      for (const watch of tagData.buyList) {
        if (Number(watch.price) <= 0 || Number(watch.quantity) <= 0) {
          toast.error("Please modify invalid data");
          ifBreak = true;
          break;
        }
      }
      if (ifBreak) break;
    }
    try {
      dispatch(updateLoading(LOADING))
      let res = (await updatePortfolioWatchs({ symbol, watchPriceData })).data;
      if (res) toast.success("Saved successfully!");
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  };
  useEffect(() => {
    let tempWatchPriceData = {};
    for (const key in tagPortfolioDataMap) {
      if (Object.hasOwnProperty.call(tagPortfolioDataMap, key)) {
        const element = tagPortfolioDataMap[key];
        let buyList = watch[element.tags]?.[Action.BUY].sort(
          (x, y) => x.price - y.price
        );
        let sellList = watch[element.tags]?.[Action.SELL].sort(
          (x, y) => x.price - y.price
        );
        tempWatchPriceData[element.tags] = {
          buyList: buyList || [],
          sellList: sellList || [],
        };
      }
    }
    setWatchPriceData(tempWatchPriceData);
  }, [tagPortfolioDataMap, watch]);
  const watchSum = useMemo(() => {
    let tempWatchSum = {}
    for (const tagId in watchPriceData) {
      tempWatchSum[tagId] = {
        buySum: watchPriceData[tagId]?.buyList?.reduce((partialSum, a) => partialSum + a.price * a.quantity, 0),
        sellSum: watchPriceData[tagId]?.sellList?.reduce((partialSum, a) => partialSum + a.price * a.quantity, 0)
      }
    }
    return tempWatchSum;
  }, [watchPriceData])
  const refreshEquityData = useCallback(async () => {
    try {
      dispatch(updateLoading(LOADING))
      let companyAccountsData = (await fetchCompanyAccounts(symbol)).data
      if (companyAccountsData) setAccountStocks(companyAccountsData);
      let portfolioData = (await fetchPortfolio(symbol)).data
      if (portfolioData) setPortfolio(portfolioData);
      let tradesData = (await fetchTrades(symbol)).data
      if (tradesData) setTrades(tradesData);
      let watchData = (await fetchWatch(symbol)).data
      if (watchData) setWatch(watchData);
      let lastCyclesData = (await fetchLastCycles()).data
      if (lastCyclesData) dispatch(updateCycles(lastCyclesData));
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  }, [symbol, dispatch]);
  useEffect(() => {
    refreshEquityData()
  }, [symbol, dispatch, refreshEquityData]);
  const handleRemoveTarget = (tags, action, index) => {
    let temp = JSON.parse(JSON.stringify(watchPriceData));
    if (action === Action.BUY) {
      temp[tags].buyList = temp[tags].buyList.filter((ele, i) => i !== index)
    } else {
      temp[tags].sellList = temp[tags].sellList.filter((ele, i) => i !== index)
    }
    setWatchPriceData(temp)
  }
  const handlePriceListChange = (tags, action, change, index, value) => {
    value = value === "" ? "" : Number(value);
    let temp = JSON.parse(JSON.stringify(watchPriceData));
    if (change === Change.PRICE) {
      if (action === Action.BUY) temp[tags].buyList[index].price = value;
      else if (action === Action.SELL) temp[tags].sellList[index].price = value;
    } else if (change === Change.QUANTITY) {
      if (action === Action.BUY) temp[tags].buyList[index].quantity = value;
      else if (action === Action.SELL)
        temp[tags].sellList[index].quantity = value;
    }
    setWatchPriceData(temp);
  };
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const handleAddWatch = (action, tags) => {
    let temp = JSON.parse(JSON.stringify(watchPriceData));
    if (action === Action.BUY)
      temp[tags].buyList = [{ quantity: 0, price: 0 }, ...temp[tags].buyList];
    else if (action === Action.SELL)
      temp[tags].sellList.push({ quantity: 0, price: 0 });
    setWatchPriceData(temp);
  };

  const handleRemoveWatch = (action, tags) => {
    let temp = JSON.parse(JSON.stringify(watchPriceData));
    if (action === Action.BUY) temp[tags].buyList.shift();
    else if (action === Action.SELL)
      temp[tags].sellList.pop(temp[tags].sellList.length - 1);
    setWatchPriceData(temp);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        sortBy: [
          {
            id: 'executed_ts',
            desc: true
          }
        ]
      }
    },
    useFilters,
    useSortBy
  );
  const stats = useMemo(() => {
    let temp = {
      bought_value: 0,
      bought_qty: 0,
      sold_value: 0,
      stock: 0,
      average: 0,
      bought_brokerage: 0,
      sold_brokerage: 0,
      unrealisedValue: 0,
      netValue: 0
    }
    rows.forEach(({ values: { action, price, quantity, brokerage } }) => {
      if (action === Action.BUY) {
        temp.bought_brokerage += Number(brokerage)
        temp.bought_value += Number(price) * Number(quantity) + Number(brokerage)
        temp.bought_qty += Number(quantity)
        temp.stock += Number(quantity)
      } else {
        temp.sold_brokerage += Number(brokerage)
        temp.sold_value += Number(price) * Number(quantity) - Number(brokerage)
        temp.stock -= Number(quantity)
      }
    })
    temp.average = Number(temp.bought_qty) !== 0 ? (Number(temp.bought_value) + Number(temp.bought_brokerage)) / Number(temp.bought_qty) : 0
    temp.turnover = Number(temp.bought_value) + Number(temp.sold_value) + 2 * Number(temp.sold_brokerage)
    temp.cash = Number(temp.sold_value) - Number(temp.bought_value)

    temp.unrealisedValue = Number(temp.stock) * Number(equity?.ltp)
    temp.netValue = Number(temp.unrealisedValue) + Number(temp.cash)
    return temp;
  }, [rows, equity])
  const quantityAggregate = useMemo(() => {
    let temp = {}
    trades.forEach(trade => {
      if (trade?.tags?.id) {
        if (!(trade?.tags?.id in temp)) {
          temp[trade.tags.id] = { buy: 0, sell: 0 }
        }
        if (trade.cycle === lastCycles[symbol]?.cycle || trade.tags.name === PROFIT) {
          if (trade.action === Action.BUY) temp[trade.tags.id].buy += trade.quantity
          else temp[trade.tags.id].sell += trade.quantity
        }
      }
    });
    return temp;
  }, [trades, lastCycles, symbol])
  return (
    <Container>
      <NavigateToSignIn />
      <LinksModal
        show={showModal === LINKS_MODAL}
        setShow={setShowModal}
        symbol={symbol}
        equityLinks={equity?.links}
      />
      <ConfirmationModal
        show={showModal === CONFIRMATION_MODAL}
        setShow={setShowModal}
        title={"Confirm!"}
        body={"This change is not reversible. Please confirm."}
        callback={runCloseCycle} />
      <WatchTemplateModal
        show={showModal === WATCH_MODAL}
        setShow={setShowModal}
        tag={currentTag}
        watchPriceData={watchPriceData}
        setWatchPriceData={setWatchPriceData}
      />
      <NavigateToSignIn />
      <Row>
        <div className="flex justify-between">
          <h1>{symbol} : {Number(equity?.ltp)?.toFixed(2)}
            <h4 className="inline align-middle">
              <Badge className="ml-3">{BroadIndex[equity?.broad_index]}</Badge>
            </h4>
            <span className="text-base ml-3">{momentTimezone(equity?.last_updated).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss")}</span>
            <h6>
              <Badge bg="warning" onClick={() => setShowModal(LINKS_MODAL)}>Edit</Badge>  &nbsp;
              {equity?.links?.map((link) => <a className="mr-2" target="_blank" rel="noopener noreferrer" href={link.link}>{link.name}</a>)}
            </h6>
          </h1>
          <div>
            <Form.Group as={Row} className="mb-3" controlId="color">
              <Form.Select aria-label="Default select example" onChange={handleColorChange} value={equity?.color || ''}>
                <option value="">General</option>
                <option value="GRE">Green</option>
                <option value="YEL">Yellow</option>
                <option value="RED">Red</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </Row>
      {!lastCycle && <Row>
        <h3>Lifetime Stats</h3>
        <div className="flex justify-between">
          <h4>Unrealised : {stats?.unrealisedValue?.toFixed(2)}</h4>
          <h4>Net : {Number(portfolio?.validate_equity?.net_cash_open_cycle + portfolio?.validate_equity?.net_cash_closed_cycle)?.toFixed(2)}</h4>
          <h4>Unrealised +Net : {stats?.netValue?.toFixed(2)}</h4>
        </div>
      </Row>}
      <Row className="text-slate-600">
        <Col className="font-bold italic underline">
          <h2>Total</h2>
          <h4>
            {accountStocks?.reduce((partialSum, a) => partialSum + a.quantity, 0)} units
          </h4>
        </Col>
        {accountStocks.map((p) => (
          <Col key={p.account}>
            <h2>{accountsMap[p.account]?.client}</h2>
            <h4>
              {p.quantity} units
            </h4>
          </Col>
        ))}
        <Col sm={2}>
          <Button
            variant="warning"
            className="w-full h-full"
            onClick={handleCloseCycle}
            disabled={lastCycles[symbol]?.closed}
          >
            Close Cycle
          </Button>
        </Col>
        <Col sm={2}>
          <Button
            variant="success"
            className="w-full h-full"
            onClick={handleWatchSave}
          >
            Save
          </Button>
        </Col>
      </Row>
      {tags && watchPriceData && Object.keys(watchPriceData).length !== 0 && (
        <>
          <hr />
          {tags.map((tag) => {
            let watchData = watchPriceData[tag.id];
            if (!watchData) return <></>
            let showBuySell = Boolean(quantityAggregate[tag.id] && Number(tagPortfolioDataMap[tag.id].quantity))
            let ifNormalTag = Boolean(tagsMap[tag.id]?.name === NORMAL)
            return (
              <div key={tag.id}>
                <Row>
                  <Col sm={2}>
                    <h3 className="inline">{tag.name}</h3>
                  </Col>
                  <Col sm={2} className="font-semibold">
                    <BiRupee className="inline" />{tagPortfolioDataMap[tag.id].average}
                    <AiFillGold className="inline ml-3" />{tagPortfolioDataMap[tag.id].quantity}
                  </Col>
                  <Col className="font-semibold">
                    <Row>
                      <Col>
                        NET <BiRupee className="inline" />{Number(ifNormalTag ? portfolio.validate_equity.net_cash_open_cycle : portfolio.validate_equity.net_cash_closed_cycle)?.toFixed(2)}
                      </Col>
                      {showBuySell && <>
                        <Col>
                          BUY <AiFillGold className="inline" /> {quantityAggregate[tag.id]?.buy}
                        </Col>
                        <Col>
                          SELL <AiFillGold className="inline" /> {quantityAggregate[tag.id]?.sell}
                        </Col>
                      </>
                      }
                      {!showBuySell && <Col> No trades recorded</Col>}
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="primary"
                      onClick={(e) => handleAddWatch(Action.BUY, tag.id)}
                    >
                      <HiPlus />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={(e) => handleRemoveWatch(Action.BUY, tag.id)}
                    >
                      <HiMinus />
                    </Button>
                    {watchData &&
                      watchData.buyList?.map((w, index) => (
                        <Button variant="light" key={index}>
                          <div>{(Number(w.price) * Number(w.quantity)).toFixed(2)}</div>
                          <Form.Control
                            type="number"
                            value={w.price}
                            onChange={(e) =>
                              handlePriceListChange(
                                tag.id,
                                Action.BUY,
                                Change.PRICE,
                                index,
                                e.target.value
                              )
                            }
                            isInvalid={
                              typeof w.price !== "number" ||
                              Number(w.price) <= 0
                            }
                          />
                          <Form.Control
                            type="number"
                            value={w.quantity}
                            onChange={(e) =>
                              handlePriceListChange(
                                tag.id,
                                Action.BUY,
                                Change.QUANTITY,
                                index,
                                e.target.value
                              )
                            }
                            isInvalid={
                              typeof w.quantity !== "number" ||
                              Number(w.quantity) <= 0
                            }
                          />
                          <Button onClick={() => handleRemoveTarget(
                            tag.id,
                            Action.BUY,
                            index,
                          )}>Remove</Button>
                        </Button>
                      ))}
                    <Button variant="success" disabled>
                      <div>{watchSum[tag.id]?.buySum}</div>
                      <div className="flex flex-col justify-end h-3/4">
                        <div>
                          <BiRupee />
                          BUY
                          <AiFillGold />
                        </div>
                      </div>
                    </Button>
                    <Button variant="warning" onClick={() => openWatchModal(tag.id)}>
                      <div>{watchSum[tag.id]?.sellSum}</div>
                      <div className="flex flex-col justify-end h-3/4">
                        <div>
                          <BiRupee />
                          SELL
                          <AiFillGold />
                        </div>
                      </div>
                    </Button>
                    {watchData &&
                      watchData.sellList?.map((w, index) => (
                        <Button variant="light" key={index}>
                          <div>{(Number(w.price) * Number(w.quantity)).toFixed(2)}</div>
                          <Form.Control
                            type="number"
                            value={w.price}
                            onChange={(e) =>
                              handlePriceListChange(
                                tag.id,
                                Action.SELL,
                                Change.PRICE,
                                index,
                                e.target.value
                              )
                            }
                            isInvalid={
                              typeof w.price !== "number" ||
                              Number(w.price) <= 0
                            }
                          />
                          <Form.Control
                            type="number"
                            value={w.quantity}
                            onChange={(e) =>
                              handlePriceListChange(
                                tag.id,
                                Action.SELL,
                                Change.QUANTITY,
                                index,
                                e.target.value
                              )
                            }
                            isInvalid={
                              typeof w.quantity !== "number" ||
                              Number(w.quantity) <= 0
                            }
                          />
                          <Button onClick={() => handleRemoveTarget(
                            tag.id,
                            Action.SELL,
                            index,
                          )}>Remove</Button>
                        </Button>
                      ))}
                    <Button
                      variant="danger"
                      onClick={(e) => handleRemoveWatch(Action.SELL, tag.id)}
                    >
                      <HiMinus />
                    </Button>
                    <Button
                      variant="primary"
                      onClick={(e) => handleAddWatch(Action.SELL, tag.id)}
                    >
                      <HiPlus />
                    </Button>
                  </ButtonGroup>
                </Row>
                <hr />
              </div>
            );
          })}
        </>
      )}
      <Row className="mb-3">
        <Button
          variant="primary"
          className="w-full h-full"
          onClick={() => setLastCycle((last) => !last)}
        >
          Show {lastCycle ? "All" : "Last"} Cycle
        </Button>
      </Row>
      <Row>
        <Table bordered>
          <tbody>
            <tr>
              <td>Net Cash : {stats.cash?.toFixed(2)}</td>
              <td>Turnover : {stats.turnover?.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Bought Value : {stats.bought_value?.toFixed(2)}</td>
              <td>Sold Value : {stats.sold_value?.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Stock Left : {stats.stock}</td>
              <td>Average : {stats.average?.toFixed(2)}</td>
            </tr>
          </tbody>
        </Table>
      </Row>
      <Row>
        <Table striped bordered hover className="mt-3" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <div>
                      {column.canFilter
                        ? column.render((filterInstance) => {
                          if (filterInstance.column.id === "executed_ts") {
                            return DateFilter(filterInstance);
                          } else if (
                            [
                              "account",
                              "tags",
                              "action",
                              "cycle",
                              "product"
                            ].includes(filterInstance.column.id)
                          ) {
                            return SelectColumnFilter(filterInstance);
                          }
                          return <div>&nbsp;</div>;
                        })
                        : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>
                        {cell.render((renderRow) => {
                          if (renderRow.column.id === "tags") {
                            return (
                              <>{renderRow.value?.name}</>
                            );
                          } else if (renderRow.column.id === "account") {
                            return (
                              <>{renderRow.value?.client}</>
                            );
                          } else if (renderRow.column.id === "symbol") {
                            return (
                              <Link to={`/equities/${renderRow.value}`}>
                                {renderRow.value}
                              </Link>
                            );
                          }
                          return <>{renderRow.value}</>;
                        })}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default EquityPage;

import React, { useState } from 'react';
import { Button, Col, FloatingLabel, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addNewStock, fetchAllEquitySymbols, fetchNewEquityInfo } from '../../Api';
import { LOADING } from '../../Constants';
import { updateLoading } from '../../slices/Loading';
import { setAllSymbols } from '../../slices/AllSymbols';

const AddNewStockModal = ({ modalShow, setModalShow }) => {

    const dispatch = useDispatch()
    const [inin, setIsin] = useState("")
    const [invalid, setInvalid] = useState(false)
    const [details, setDetails] = useState()
    const resetModal = () => {
        setIsin("")
        setInvalid(false)
        setDetails(undefined)
    }
    const handleAddNewStock = async () => {
        try {
            let res = (await addNewStock(details)).data
            toast.success(res?.detail)
            let allSymbolsData = (await fetchAllEquitySymbols()).data
            if (allSymbolsData) dispatch(setAllSymbols(allSymbolsData));
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            dispatch(updateLoading(""))
        }
    }
    const handleFetchNewEquityInfo = async (e) => {
        e.preventDefault()
        if (!inin) {
            setInvalid(true)
            return
        }
        setInvalid(false)
        try {
            dispatch(updateLoading(LOADING))
            let res = (await fetchNewEquityInfo(inin))?.data
            setDetails(res)
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            dispatch(updateLoading(""))
        }
    }


    return (
        <Modal
            show={modalShow}
            onHide={() => {
                setModalShow(false)
                resetModal()
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs={8}>
                        <FloatingLabel
                            controlId="newStockSymbol"
                            label="ISIN"
                        >
                            <Form.Control type="text" value={inin} onChange={(e) => setIsin(e.target.value)} isInvalid={invalid} />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <Button variant="success" className="h-full w-full" onClick={handleFetchNewEquityInfo}>
                            Find details
                        </Button>
                    </Col>
                </Row>
                {details && <><hr />
                    <ListGroup as="ol" className='mt-3'>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Name</div>
                                {details.exSymName}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Symbol</div>
                                {details.exSymbol}
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className={`ms-2 ${details.exchangeName === "NSE" ? "me-auto" : "w-full bg-amber-400"}`}>
                                <div className="fw-bold">Exchange</div>
                                {details.exchangeName}
                            </div>
                        </ListGroup.Item><ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Previous Close</div>
                                {details.previousClose}
                            </div>
                        </ListGroup.Item><ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">Ticker</div>
                                {details.symTicker}
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="ml-auto" disabled={!details} onClick={handleAddNewStock}>
                    Add this Equity
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddNewStockModal;
import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Col, Form, Row, ToggleButton } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTrade, deleteTrade, fetchLiveEquity, updateTrade } from "../../Api";
import { DATE_FORMAT, LOADING, PROFIT, TIMESTAMP_FORMAT, TRADE_NORMAL, TRADE_UPDATE, productMap } from "../../Constants";
import { updateLoading } from "../../slices/Loading";
import SymbolTypeahead from "../SymbolTypeahead";
import { setLiveEquities } from "../../slices/Live";

function EquityTradeModal({
  modalShow,
  setModalShow,
  uniqueEquities,
  accounts,
  tags,
  row,
  setRefresh,
  mode,
}) {
  const dispatch = useDispatch();
  const [nextCycle, setNextCycle] = useState(false);
  const [validated, setValidated] = useState(false);
  const [cycleValue, setCycleValue] = useState("");
  const lastCycles = useSelector((state) => state.lastCycles.lastCycles);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange"
  });
  const symbol = watch('symbol')
  const tagsSelected = watch('tags')
  const handleDelete = async (e) => {
    e.preventDefault()
    try {
      dispatch(updateLoading(LOADING))
      let res
      res = (await deleteTrade(row.id));
      if (res) toast.success("Trade deleted!");
      else toast.error("Delete Failed!")
      setModalShow(false);
      setRefresh(true);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    } finally {
      dispatch(updateLoading(""))
    }
  };
  useEffect(() => {
    if (lastCycles[symbol]?.closed) setNextCycle(true)
    else setNextCycle(false)
  }, [symbol, lastCycles])
  const onSubmit = async (data) => {
    if (isValid) {
      data.executed_ts = moment(`${data.date} ${data.time}`, TIMESTAMP_FORMAT)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      data.price = Number(data.price);
      data.quantity = Number(data.quantity);
      data.brokerage = Number(data.brokerage);
      data.symbol = data.symbol[0]
      data.cycle = cycleValue
      try {
        let success = false;
        dispatch(updateLoading(LOADING))
        if (mode === TRADE_UPDATE) {
          success = await updateTrade(row.id, data);
        } else if (mode === TRADE_NORMAL) {
          success = await addTrade(data);
          if (newStock) {
            let live = (await fetchLiveEquity()).data
            dispatch(setLiveEquities(live))
          }
        }
        if (success) {
          setModalShow(false);
          setRefresh(true);
          toast.success(`Trade ${mode === TRADE_UPDATE ? "updated" : "added"}!`)
        }
      } catch (err) {
        toast.error(`Error: ${err.message}`);
      } finally {
        dispatch(updateLoading(""))
      }
    } else {
      setValidated(true);
    }
  };
  useEffect(() => {
    setValidated(false);
    setValue("action", row.action);
    if (mode === TRADE_UPDATE) {
      setValue("price", row.price);
      setValue("quantity", row.quantity);
      setValue("brokerage", Number(row.brokerage) === 0 ? "" : Number(row.brokerage));
      setCycleValue(row.cycle)
      const productSelected = Object.keys(productMap).find((productKey) => productMap[productKey] === row.product)
      setValue("product", productSelected);
      setValue("comment", row.comment);
      setValue("cycle", row.cycle);
    } else {
      setValue("price", row.ltp);
      setValue("quantity", "");
      setValue("brokerage", "");
      setValue("product", "");
      setValue("cycle", "");
      setValue("comment", "");
    }
    setValue("account", row?.account?.id);
    setValue(
      "date",
      row.timestamp
        ? row.timestamp.format(DATE_FORMAT)
        : moment().format(DATE_FORMAT)
    );
    setValue(
      "time",
      row.timestamp ? row.timestamp.format("HH:mm:ss") : "15:00:00"
    );
    setValue("tags", row?.tags?.id);
  }, [row, setValue, modalShow, mode]);
  let title
  switch (mode) {
    case TRADE_UPDATE:
      title = "Update Trade"
      break
    case TRADE_NORMAL:
    default:
      title = "Add Trade"
  }

  useEffect(() => {
    let res = tags.find((tag) => tag.id === Number(tagsSelected))
    if (res?.name === PROFIT) setCycleValue(0)
    else if (symbol?.length) {
      if (mode !== TRADE_UPDATE) {
        if (nextCycle) {
          if (lastCycles[symbol]) setCycleValue(lastCycles[symbol]?.cycle + 1)
          else setCycleValue(1)
        } else {
          if (lastCycles[symbol]) setCycleValue(lastCycles[symbol]?.cycle)
          else setCycleValue(1)
        }
      }
    }
  }, [nextCycle, symbol, setCycleValue, lastCycles, tagsSelected, mode, tags])

  const { cycleTag, cycleClosed, newStock } = useMemo(() => {
    let cycleTag = <></>;
    let cycleClosed = false
    let newStock = false
    if (lastCycles && symbol in lastCycles) {
      cycleClosed = lastCycles[symbol].closed
      if (lastCycles[symbol]['cycle']) {
        cycleTag = <Badge bg='primary'>Cycle {cycleValue}</Badge>
      } else {
        cycleTag = <Badge bg='success'>New Cycle</Badge>
      }
    } else {
      cycleTag = <Badge bg='info'>New Stock</Badge>
      newStock = true
    }
    return { cycleTag, cycleClosed, newStock }
  }, [lastCycles, symbol, cycleValue])

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
        <div className="ml-12"><h3 className="mb-auto">{cycleTag}</h3>
        </div>
        <div className="ml-12"><h3 className="mb-auto"><Badge bg={cycleClosed ? 'warning' : 'success'}>{cycleClosed ? "Closed" : "Open"}</Badge></h3>
        </div>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate validated={validated}>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3" >
            <Form.Label column sm={"2"}>
              Symbol
            </Form.Label>
            <Col sm="10">
              <SymbolTypeahead options={uniqueEquities} control={control} name='symbol' defaultValue={row?.symbol ? row.symbol : []} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="cycle">
            <Form.Label column sm={"2"}>
              Cycle
            </Form.Label>
            <Col sm="6">
              <Form.Control
                required
                type="number"
                value={cycleValue}
                onChange={(e) => setCycleValue(e.target.value === "" ? "" : Number(e.target.value))}
              />
            </Col>
            <Col sm="4">
              <ButtonGroup>
                <ToggleButton
                  id={'radio-current'}
                  type="radio"
                  variant='outline-success'
                  name="cycle-switch"
                  disabled={true}
                  checked={!nextCycle}
                  onChange={() => { setNextCycle(false) }}
                >
                  Current
                </ToggleButton>
                <ToggleButton
                  id={'radio-next'}
                  type="radio"
                  variant='outline-success'
                  name="cycle-switch"
                  disabled={true}
                  checked={nextCycle}
                  onChange={() => { setNextCycle(true) }}
                >
                  Next
                </ToggleButton>
              </ButtonGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="product">
            <Form.Label column sm={"2"}>
              Product
            </Form.Label>
            <Col sm="10">
              <Form.Select {...register("product", { required: { value: true, message: "Product Required" } })} isInvalid={!!errors.product}>
                {Object.keys(productMap).map((productKey) => <option key={productKey} value={productKey}>{productMap[productKey]}</option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.product?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="action">
            <Form.Label column sm={"2"}>
              Action
            </Form.Label>
            <Col sm="10">
              <Form.Select {...register("action", { required: { value: true, message: "Action Required" } })}
                isInvalid={!!errors.action}>
                <option value="B">BUY</option>
                <option value="S">SELL</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.action?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="quantity">
            <Form.Label column sm={"2"}>
              Quatity
            </Form.Label>
            <Col sm="10">
              <Form.Control
                required
                type="number"
                isInvalid={!!errors.quantity}
                {...register("quantity", {
                  required: { value: true, message: "Quatity Required" },
                  min: { value: 1, message: "Minimum value should be 1" },
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.quantity?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="price">
            <Form.Label column sm={"2"}>
              Price
            </Form.Label>
            <Col sm="10">
              <Form.Control
                isInvalid={!!errors.price}
                type="number"
                {...register("price", {
                  required: { value: true, message: "Price Required" },
                  min: { value: 0, message: "Minimum value should be 0" },
                })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.price?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="brokerage">
            <Form.Label column sm={"2"}>
              Brokerage
            </Form.Label>
            <Col sm="10">
              <Form.Control type="number" {...register("brokerage")} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="account">
            <Form.Label column sm={"2"}>
              Account
            </Form.Label>
            <Col sm="10">
              <Form.Select
                isInvalid={!!errors.account}
                type="text"
                {...register("account", {
                  required: { value: true, message: "Account Required" },
                })}
              >
                {Array.from(accounts).map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.client}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.account?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="date">
            <Form.Label column sm={"2"}>
              Date
            </Form.Label>
            <Col sm="10">
              <Form.Control
                isInvalid={!!errors.date}
                type="date"
                {...register("date", { required: true })}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.date?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="time">
            <Form.Label column sm={"2"}>
              Time
            </Form.Label>
            <Col sm="10">
              <Form.Control type="time" {...register("time")} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="tags">
            <Form.Label column sm={"2"}>
              Tag
            </Form.Label>
            <Col sm="10">
              <Form.Select
                type="text"
                isInvalid={!!errors.tags}
                {...register("tags", {
                  required: { value: true, message: "Tag Required" },
                })}
              >
                {Array.from(tags).map((tag) => (
                  <option key={tag.id} value={tag.id}>
                    {tag.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.tags?.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="comment">
            <Form.Label column sm={"2"}>
              Comment
            </Form.Label>
            <Col sm="10">
              <Form.Control as="textarea" {...register("comment")} />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {mode === TRADE_UPDATE && (
            <Button
              variant="danger"
              type="reset"
              className="ml-auto"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
          {(mode === TRADE_NORMAL || mode === TRADE_UPDATE) && <Button variant="primary" type="submit" className="ml-auto">
            Submit
          </Button>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EquityTradeModal;

import axios from "axios";
import { toast } from "react-toastify";
let api_url;
if(document.location.hostname==='localhost'){
  api_url = "http://localhost:8000/";
}else{
  api_url = "https://api.traderapps.namveedu.in/";
}
export const API_URL = api_url

export const AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 600000,
});

AxiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = JSON.parse(localStorage.getItem("auth"))?.refresh;
      return AxiosInstance.post("api/token/refresh/", {
        refresh: refreshToken,
      }).then(async (res) => {
        if (res.status === 200) {
          localStorage.setItem("auth", JSON.stringify(res.data));
          AxiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.access;
            originalRequest.headers.Authorization = "Bearer " + res.data.access;
          return await AxiosInstance(originalRequest);
        }
      }).catch(async (res) => {
        console.log("ERROR: ", res);
      });
    }
    let detail = error?.response?.data?.detail;
    if (detail) {
      toast.error(detail)
      return Promise.resolve(false)
    }else{
      toast.error(JSON.stringify(error?.response?.data ? error.response.data: "ERROR"))
      return Promise.resolve(false)
    };
    // return Promise.reject(error);
  }
);
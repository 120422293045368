import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import AddNewStockModal from '../Modals/AddNewStockModal';
import NavigateToSignIn from '../NavigateToSignIn';
import { clearCache } from '../../Api';
import { toast } from 'react-toastify';
import { updateLoading } from '../../slices/Loading';
import { LOADING } from '../../Constants';
import { useDispatch } from 'react-redux';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { Link } from 'react-router-dom';

const AdminActionsPage = () => {

    const dispatch = useDispatch();
    const [showNewStockModal, setShowNewStockModal] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClearCache = async () => {
        try {
            dispatch(updateLoading(LOADING))
            let res = (await clearCache()).data
            toast.success(res?.detail)
        } catch (err) {
            toast.error(`Error: ${err.message}`);
        } finally {
            dispatch(updateLoading(""))
        }
    }
    return (
        <div>
            <NavigateToSignIn />
            <AddNewStockModal
                modalShow={showNewStockModal}
                setModalShow={setShowNewStockModal}
            />
            <ConfirmationModal
                show={showConfirmation}
                setShow={setShowConfirmation}
                title={"Confirm!"}
                body={"This is a compute heavy process. Do you wish to confirm?"}
                callback={handleClearCache} />
            <h1>
                <Badge bg="primary" className='hover:shadow-xl' onClick={() => setShowNewStockModal(true)}>Add new stock</Badge>
            </h1>
            <h1>
                <Badge bg="primary" className='hover:shadow-xl' onClick={() => setShowConfirmation(true)}>Clear Cache</Badge>
            </h1>
            <Link to={"/export-import"} className="block w-fit">
                <h1>
                    <Badge bg="primary">Index Update</Badge>
                </h1>
            </Link>
        </div>
    );
};

export default AdminActionsPage;
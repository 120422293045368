import React from 'react';
import { Form } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useController } from "react-hook-form";

const SymbolTypeahead = ({ options, control, name, defaultValue }) => {

    const {
        field,
        fieldState: { invalid },
        formState: { errors },
    } = useController({
        name,
        control,
        defaultValue,
        shouldUnregister: true,
        rules: { required: { value: true, message: "Symbol required" } },
    });

    return (<>
        <Typeahead
            id="basic-typeahead-single"
            labelKey={(val) => val}
            type="text"
            isInvalid={invalid}
            onChange={field.onChange}
            options={options}
            placeholder="Choose a symbol..."
            selected={field.value}
        />
        <Form.Control.Feedback type="invalid">
            {errors?.symbol?.message}
        </Form.Control.Feedback>
    </>
    );
};

export default SymbolTypeahead;
import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    accounts: [],
  },
  reducers: {
    updateAccounts: (state, action) => {
      state.accounts = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAccounts } = accountSlice.actions;

export default accountSlice.reducer;

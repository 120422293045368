import React from 'react'
import Form from 'react-bootstrap/Form'
function DefaultColumnFilter({
  column: { filterValue, setFilter },
}){
  return (
    <Form.Control
      type="text"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter`}
      className="w-full"
    />
  );
};


export default DefaultColumnFilter
import { AxiosInstance } from "./miscellaneous";

export const login = (data) => AxiosInstance.post("api/token/", data);
export const fetchAccounts = () => AxiosInstance.get("api/account/");
export const fetchNewEquityInfo = (isin) => AxiosInstance.get("api/new-equity-info/", { params: { isin } });
export const fetchLastCycles = () => AxiosInstance.get("api/last-cycles/");
export const fetchAllEquitySymbols = () => AxiosInstance.get("api/all-equity-symbols/");
export const fetchTodayTrades = () => AxiosInstance.get("api/equity-trade/");
export const fetchTradesBetween = ({ startDate, endDate }) => AxiosInstance.get("api/trades-between/", { params: { startDate, endDate } });
export const fetchAllTags = () => AxiosInstance.get("api/tag/");
export const fetchLiveEquity = () => AxiosInstance.get("api/live-equity/");
export const fetchAllPortfolio = () => AxiosInstance.get("api/equity-portfolio/");
export const fetchAllEquityWatch = () => AxiosInstance.get("api/equity-watch/");
export const fetchEquityWatchList = () => AxiosInstance.get("api/equity-watch-list/");
export const fetchAllAccountEquityList = () => AxiosInstance.get("api/all-account-equity/");
export const fetchNetValue = () => AxiosInstance.get("api/net-value/");
export const fetchCompanyAccounts = (symbol) =>
  AxiosInstance.post("api/company-accounts/", { symbol });
export const fetchPortfolio = (symbol) =>
  AxiosInstance.post("api/company-portfolios/", { symbol });
export const fetchTrades = (symbol) =>
  AxiosInstance.post("api/company-trades/", { symbol });
export const fetchWatch = (symbol) =>
  AxiosInstance.post("api/company-watch-prices/", { symbol });

export const updatePortfolioWatchs = (data) =>
  AxiosInstance.post("api/update-portfolio-watchs/", data);
export const addTrade = (data) => AxiosInstance.post("api/equity-trade/", data);
export const updateTrade = (id, data) => AxiosInstance.put(`api/equity-trade/${id}/`, data);
export const closeCycle = (symbol) => AxiosInstance.post("api/close-cycle/", { symbol });
export const deleteTrade = (id) =>
  AxiosInstance.delete(`api/equity-trade/${id}/`);
export const importNse = () =>
  AxiosInstance.get(`import-nse/`);
export const updateColor = ({ color, symbol }) => AxiosInstance.post("api/update-color/", { color, symbol });
export const loginFyers = (auth_code) => AxiosInstance.post("api/login-fyers/", { auth_code })
export const uploadData = (formData) => AxiosInstance.post('api/upload-csvs/', formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
export const addNewStock = (details) => AxiosInstance.post("api/add-new-stock/", details);
export const clearCache = () => AxiosInstance.post("api/clear-cache/");
export const updateLinks = ({symbol, payload}) => AxiosInstance.post("api/update-links/", {symbol, payload});
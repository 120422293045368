import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginFyers } from '../../Api';
import { LOADING } from '../../Constants';
import { updateLoading } from "../../slices/Loading";

const FyersRedirectPage = props => {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        let auth_code = searchParams.get('auth_code')
        if (auth_code) {
            (async () => {
                try {
                    dispatch(updateLoading(LOADING))
                    let res = (await loginFyers(auth_code)).data
                    toast.success(res?.detail)
                } catch (err) {
                    toast.error(`Error: ${err.message}`);
                } finally {
                    dispatch(updateLoading(""))
                }
                navigate('/')
            })()
        }
    }, [searchParams, navigate, dispatch])
    return (
        <div>
            FyersRedirectPage
        </div>
    );
};

export default FyersRedirectPage;
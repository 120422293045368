import React from 'react';
import { Form } from 'react-bootstrap';

function DateFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  return (
    <><Form.Control
      type="date"
      value={filterValue?.minFilterDate}
      onChange={(e) => {
        setFilter({minFilterDate: e.target.value, maxFilterDate: filterValue?.maxFilterDate})
      }}
    />
      <Form.Control
        type="date"
        value={filterValue?.maxFilterDate}
        onChange={(e) => {
          setFilter({minFilterDate: filterValue?.minFilterDate, maxFilterDate:e.target.value})
        }}
      />
    </>
  );
}

export default DateFilter
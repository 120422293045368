import { useEffect, useMemo, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchAccounts, fetchAllEquitySymbols, fetchAllPortfolio, fetchAllTags, fetchLastCycles, fetchLiveEquity } from "../Api";
import { INTRA_MODE, SEARCH_MODE } from "../Constants";
import { updateAccounts } from "../slices/Account";
import { setAllSymbols } from "../slices/AllSymbols";
import { updateCycles } from "../slices/LastCycles";
import { setLiveEquities } from "../slices/Live";
import { updatePortfolios } from "../slices/Portfolio";
import { updateTags } from "../slices/Tags";
import { logout } from "../slices/User";

function NavBar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const portfolios = useSelector((state) => state.portfolio.portfolios);
  const user = useSelector((state) => state.user);
  const [shortcutMode, setShortcutMode] = useState(null)
  const [selected, setSelected] = useState([])
  const [intraPrice, setIntraPrice] = useState("")
  const portfolioEquities = useMemo(
    () => portfolios ? Array.from(new Set(portfolios.map((portfolio) => portfolio?.symbol))).sort() : [],
    [portfolios]
  );
  const loggedIn = useMemo(() => Boolean(user?.data?.access), [user])

  const handleSeachOnChange = (symbols) => {
    setSelected(symbols)
    let symbol = symbols[0]
    if (symbol) {
      navigate(`/equities/${symbol}`)
      setShortcutMode(false)
      setSelected([])
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setShortcutMode(null)
      setIntraPrice("")
      setSelected([])
    } else {
      if (event.ctrlKey && event.key === 'b') {
        event.preventDefault(); // Ctrl + B
        setShortcutMode(SEARCH_MODE)
        setTimeout(() => {
          document.querySelector('.shortcuts-container input').focus()
        })
      }
      if (event.ctrlKey && event.key === 'i') {
        event.preventDefault();
        setShortcutMode(INTRA_MODE)
        setTimeout(() => document.getElementById('intra-price').focus())
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    (async () => {
      if (user.data) {
        try {
          let lastCyclesData = (await fetchLastCycles()).data
          if (lastCyclesData) dispatch(updateCycles(lastCyclesData));
          let accountsData = (await fetchAccounts()).data
          if (accountsData) dispatch(updateAccounts(accountsData));
          let allTagsData = (await fetchAllTags()).data
          if (allTagsData) dispatch(updateTags(allTagsData));
          let liveEquityData = (await fetchLiveEquity()).data
          if (liveEquityData) dispatch(setLiveEquities(liveEquityData));
          let allSymbolsData = (await fetchAllEquitySymbols()).data
          if (allSymbolsData) dispatch(setAllSymbols(allSymbolsData));
          let portfolioData = (await fetchAllPortfolio())?.data;
          if (portfolioData) dispatch(updatePortfolios(portfolioData));
        } catch (e) {
          toast.error(`Error fetching details : ${e}`)
        }
      }
    })();
    return () => { }
  }, [user, dispatch])
  return (
    <>
      <Navbar bg="light" expand="lg" className="fixed-top">
        <Container>
          <Navbar.Brand as={Link} to="/">
            Trader Apps
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {loggedIn && (
              <Nav>
                <Nav.Link
                  as={Link}
                  onClick={() => {
                    localStorage.removeItem("auth");
                    dispatch(logout());
                    navigate("/sign-in");
                  }}
                >
                  Logout
                </Nav.Link>
              </Nav>
            )}
            {!loggedIn && (
              <Nav>
                <Nav.Link as={Link} to="/sign-in">
                  Sign In
                </Nav.Link>
              </Nav>
            )}
            {loggedIn && <>
              <Nav>
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/trades">
                  Trades
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/watch">
                  Watch
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/portfolio">
                  Portfolio
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/shortcuts">
                  Shortcuts
                </Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link as={Link} to="/admin-actions">
                  Admin Actions
                </Nav.Link>
              </Nav>
            </>}
            {/* <Nav>
            <Nav.Link as={Link} to="/export-import">
              Export / Import
            </Nav.Link>
          </Nav> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={shortcutMode === SEARCH_MODE ? 'block' : 'hidden'}>
        <div className="background-overlay"></div>
        <div className="shortcuts-container mt-20 mx-auto w-fit" style={{ width: "400px", maxWidth: "90%" }}>
          <Typeahead
            id="symbol-search-bar"
            labelKey={(val) => val}
            type="text"
            selected={selected}
            onChange={handleSeachOnChange}
            options={portfolioEquities}
            placeholder="Choose a symbol..."
          />
        </div>
      </div>
      <div className={shortcutMode === INTRA_MODE ? 'block' : 'hidden'}>
        <div className="background-overlay"></div>
        <div className="shortcuts-container">
          <Form.Control id="intra-price" className="mt-16 mx-auto" style={{ width: "200px" }} size="lg" type="number" placeholder="Price" value={intraPrice} onChange={(e) => setIntraPrice(e.target.value)} />
          <Row>
            <Col>
              <h3 className="mx-auto w-fit text-zinc-100">Intra</h3>
              <Row>
                <InputGroup className="m-3">
                  <Form.Control disabled value={7} />
                  <Form.Control disabled value={14} />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup className="m-3">
                  <Form.Control value={Number(intraPrice * 1.007)?.toFixed(4)} />
                  <Form.Control value={Number(intraPrice * 1.007 * 1.007)?.toFixed(4)} />
                </InputGroup>
              </Row>
            </Col>
            <Col>
              <h3 className="mx-auto w-fit text-zinc-100">Next Day</h3>
              <Row>
                <InputGroup className="m-3">
                  <Form.Control disabled value={17} />
                  <Form.Control disabled value={24} />
                </InputGroup>
              </Row>
              <Row>
                <InputGroup className="m-3">
                  <Form.Control value={Number(intraPrice * 1.017)?.toFixed(4)} />
                  <Form.Control value={Number(intraPrice * 1.017 * 1.007)?.toFixed(4)} />
                </InputGroup>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default NavBar;

import { createSlice } from "@reduxjs/toolkit";

export const liveSlice = createSlice({
  name: "live",
  initialState: {
    equities: [],
  },
  reducers: {
    setLiveEquities: (state, action) => {
      state.equities = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLiveEquities } = liveSlice.actions;

export default liveSlice.reducer;

import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { fetchLiveEquity, uploadData } from '../Api';
import { setLiveEquities } from "../slices/Live";

const FileUploadComponent = () => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const uploadedFiles = event.target.files;
        setFiles(uploadedFiles);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        try {
            const response = (await uploadData(formData)).data
            toast.success(response?.detail)
            let liveEq = (await fetchLiveEquity()).data
            if (liveEq) dispatch(setLiveEquities(liveEq));
        } catch (error) {
            toast.error("Error")
        }
    };

    return (
        <>
            <Row>

                <Form.Control type="file" multiple onChange={handleFileChange} />
                {/* </Col>
            <Col> */}

                {/* </Col> */}
            </Row>
            <Row className='mt-4'>
                <Button disabled={files.length===0} onClick={handleUpload}>Upload</Button>
            </Row>
        </>
    );
};

export default FileUploadComponent;

import { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function NavigateToSignIn() {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.data?.access) {
      toast.info("Please Sign In to access all featres");
      navigate("/sign-in");
    }
  }, [user, navigate]);
}

export default NavigateToSignIn
import React from "react";
import { Button, Form } from "react-bootstrap";
import { API_URL } from "../miscellaneous";
function DeliveryQtyTable() {
  const input = document.querySelector('input[type="file"]');

  // This will upload the file after having read it
  const upload = (file) => {
    fetch(API_URL, {
      // Your POST endpoint
      method: "POST",
      headers: {
        // Content-Type may need to be completely **omitted**
        // or you may need something
        "Content-Type": "text/csv",
      },
      body: file, // This is your file object
    })
      .then(
        (response) => response.json() // if the response is a JSON object
      )
      .then(
        (success) => console.log(success) // Handle the success response object
      )
      .catch(
        (error) => console.log(error) // Handle the error response object
      );
  };

  // Event handler executed when a file is selected
  const onSelectFile = () => upload(input);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // var file = document.querySelector("#delivery-file");
    // let formData = new FormData();
    // formData.append("deliveryFile", file, 'file.csv');
    // ("/", formData, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     "Referrer-Policy": "no-referrer",
    //   },
    // });
    // var file = document.querySelector("#delivery-file");
    // // const thisForm = document.getElementById("signup");
    // var formData = new FormData();
    // formData.append("profile", file);
    // let res = await (
    //   await fetch(API_URL, {
    //     method: "POST",
    //     body: formData,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    // ).json();
    onSelectFile();
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="delivery-file" className="mb-3">
        <Form.Label>Enter day's delivery file</Form.Label>
        <Form.Control type="file" size="lg" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default DeliveryQtyTable;

import { createSlice } from "@reduxjs/toolkit";

export const AllSymbolsSlice = createSlice({
    name: "allSymbols",
    initialState: {
        allSymbols: ""
    },
    reducers: {
        setAllSymbols: (state, action) => {
            state.allSymbols = action.payload
        }
    },
});

export const { setAllSymbols } = AllSymbolsSlice.actions;

export default AllSymbolsSlice.reducer;

export const NORMAL = 'NORMAL'
export const PROFIT = 'PROFIT'
export const TRADE_NORMAL = 'TRADE_NORMAL'
export const TRADE_UPDATE = 'TRADE_UPDATE'
export const TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DATE_FORMAT = "YYYY-MM-DD"
export const CRASH_SELL_STRATEGY = "CRASH_SELL_STRATEGY"
export const NEXT_DAY_SELL_STRATEGY = "NEXT_DAY_SELL_STRATEGY"
export const NORMAL_SELL_STRATEGY = "NORMAL_SELL_STRATEGY"
export const PRICE_KEY = "PRICE_KEY"
export const QUANTITY_KEY = "QUANTITY_KEY"
export const PERCENATGE_KEY = "PERCENATGE_KEY"
export const FYERS_CLIENT_ID = "PVH4TN7WQZ-100"
export const REDIRECT_URI = "https://traderapps.namveedu.in/fyers-redirect-uri"
export const FYERS_LOGIN = `https://api-t1.fyers.in/api/v3/generate-authcode?client_id=${FYERS_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&state=None`
export const Action = Object.freeze({
  BUY: "B",
  SELL: "S",
});

export const productMap = {
  "D": "DELIVERY",
  "I": "INTRADAY",
  "B": "BTST",
  "M": "MTF",
}

export const colorMap = {
  "RED": 'red-row',
  "YEL": 'yel-row',
  "GRE": 'gre-row',
}

export const BroadIndex = {
  "0": "OTHERS",
  "1": "NIFTY 50",
  "2": "NEXT 50",
  "3": "MIDCAP 150",
  "4": "SMALLCAP 250",
  "5": "MICROCAP 250",
}

export const SEARCH_MODE = "SEARCH_MODE"
export const INTRA_MODE = "INTRA_MODE"
export const LOADING = "Loading..."
export const WATCH_MODAL = "WATCH_MODAL"
export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL"
export const LINKS_MODAL = "LINKS_MODAL"
export const LINK_KEY_NAME = "LINK_KEY_NAME" 
export const LINK_KEY_VALUE = "LINK_KEY_VALUE" 
export const DAY = "Day"
export const IMPULSE = "Impulse"
export const DIMPULSE = "DImpulse"
export const SIMPLUSE = "SImpluse"
export const ALL_LINKS = "All Links"
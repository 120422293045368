import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Form, Modal, Row, ToggleButton } from 'react-bootstrap';
import { CRASH_SELL_STRATEGY, NEXT_DAY_SELL_STRATEGY, NORMAL_SELL_STRATEGY, PERCENATGE_KEY, PRICE_KEY, QUANTITY_KEY } from '../../Constants';

function WatchTemplateModal({
    show,
    setShow,
    tag,
    watchPriceData,
    setWatchPriceData
}) {
    const [sellStrategy, setSellStrategy] = useState(NORMAL_SELL_STRATEGY);
    const [proposedPrice, setProposedPrice] = useState();
    const [proposedQuantity, setProposedQuantity] = useState();
    const [proposedPercentage, setProposedPercentage] = useState(5);
    const [prices, setPrices] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [percentages, setPercentages] = useState([]);
    const [disableInit, setDisableInit] = useState(false)
    const [disablePercentage, setDisablePercentage] = useState(false)
    const handleReset = () => {
        setSellStrategy(NORMAL_SELL_STRATEGY)
        setProposedPrice("")
        setProposedQuantity("")
        setProposedPercentage(5)
        setPrices([])
        setQuantities([])
        setPercentages([])
        setDisableInit(false)
        setDisablePercentage(false)
    }
    const handleListChange = (key, index, value) => {
        if (key === PRICE_KEY) {
            let list = JSON.parse(JSON.stringify(prices))
            list[index] = value
            setPrices(list)
            setDisablePercentage(true)
        } else if (key === QUANTITY_KEY) {
            let list = JSON.parse(JSON.stringify(quantities))
            list[index] = value
            setQuantities(list)
        } else if (key === PERCENATGE_KEY) {
            let list = JSON.parse(JSON.stringify(percentages))
            list[index] = value
            setPercentages(list)
            let priceList = JSON.parse(JSON.stringify(prices))
            priceList[index] = Number(proposedPrice * (1 + (Number(value) / 100)))?.toFixed(2)
            setPrices(priceList)
        }
        setDisableInit(true)
    }
    const handleSubmit = () => {
        let watchPriceDataTemp = JSON.parse(JSON.stringify(watchPriceData))
        let sellList = [...watchPriceDataTemp[tag].sellList]
        prices.forEach((price, index) => {
            sellList.push({
                price: Number(Number(price)?.toFixed(2)),
                quantity: Number(quantities[index])
            })
        });
        watchPriceDataTemp[tag].sellList = sellList;
        setWatchPriceData(watchPriceDataTemp)
        handleReset()
        setShow(false)
    }
    useEffect(() => {
        let quantityList
        let pricesList
        let percentageList
        if (sellStrategy === NORMAL_SELL_STRATEGY && proposedPrice && proposedQuantity) {
            quantityList = [Math.floor(proposedQuantity / 3), Math.floor(proposedQuantity / 3)]
            quantityList.push(proposedQuantity - quantityList.reduce((partialSum, a) => partialSum + a, 0))
            pricesList = [(proposedPrice * 1.05)?.toFixed(2), (proposedPrice * 1.0794)?.toFixed(2), (proposedPrice * 1.11)?.toFixed(2)]
            percentageList = [5, 7.94, 11]
        } else if (sellStrategy === CRASH_SELL_STRATEGY && proposedPrice && proposedQuantity && proposedPercentage) {
            quantityList = []
            pricesList = []
            percentageList = []
            let iterationQty = proposedQuantity
            let iterationPrice = proposedPrice
            let iterationPercentage = 1
            for (let i = 0; i < 6; i++) {
                let qty = Math.round(iterationQty * .1)
                quantityList.push(qty)
                iterationQty -= qty

                let price = iterationPrice * (1 + (proposedPercentage / 100))
                pricesList.push(price?.toFixed(2))
                iterationPrice = price

                let percentage = (iterationPercentage * (1 + (proposedPercentage / 100)))
                percentageList.push(((percentage - 1) * 100)?.toFixed(2))
                iterationPercentage = percentage
            }
        }else if(sellStrategy === NEXT_DAY_SELL_STRATEGY && proposedPrice && proposedQuantity){
            let first_sell = Math.ceil(proposedQuantity/2)
            quantityList = [first_sell,proposedQuantity-first_sell]
            pricesList = []
            percentageList = [1.7, 2.4119]
            for(let percentage of percentageList){
                pricesList.push(((1+(percentage/100))*proposedPrice)?.toFixed(2))
            }
        }
        if (quantityList) setQuantities(quantityList)
        if (pricesList) setPrices(pricesList)
        if (percentageList) setPercentages(percentageList)
    }, [sellStrategy, proposedPrice, proposedQuantity, proposedPercentage])

    let ifShowList = proposedPrice && proposedQuantity && (sellStrategy === NORMAL_SELL_STRATEGY || (sellStrategy === CRASH_SELL_STRATEGY && proposedPercentage) || sellStrategy === NEXT_DAY_SELL_STRATEGY)
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Watch Template
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col size={4}>
                        <Button className='w-full' onClick={handleReset}>Reset</Button>
                    </Col>
                    <Col size={8}>
                        <ButtonGroup className="ml-auto w-full">
                            <ToggleButton
                                id={`normal`}
                                type="radio"
                                variant={"outline-success"}
                                name="sell-strategy"
                                value={NORMAL_SELL_STRATEGY}
                                checked={sellStrategy === NORMAL_SELL_STRATEGY}
                                disabled={disableInit}
                                onChange={(e) => setSellStrategy(e.target.value)}
                            >
                                Normal
                            </ToggleButton>
                            <ToggleButton
                                id={`crash-sell-strategy`}
                                type="radio"
                                variant={"outline-success"}
                                name="sell-strategy"
                                value={CRASH_SELL_STRATEGY}
                                checked={sellStrategy === CRASH_SELL_STRATEGY}
                                disabled={disableInit}
                                onChange={(e) => setSellStrategy(e.target.value)}
                            >
                                Crash
                            </ToggleButton>
                            <ToggleButton
                                id={`next-day-sell-strategy`}
                                type="radio"
                                variant={"outline-success"}
                                name="sell-strategy"
                                value={NEXT_DAY_SELL_STRATEGY}
                                checked={sellStrategy === NEXT_DAY_SELL_STRATEGY}
                                onChange={(e) => setSellStrategy(e.target.value)}
                            >
                                Next Day
                            </ToggleButton>
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Form.Group as={Col} className="mb-3" controlId="quantity">
                        <Form.Label column>
                            Proposed Quantity
                        </Form.Label>
                        <Form.Control
                            required
                            type="number"
                            value={proposedQuantity}
                            disabled={disableInit}
                            onChange={(e) => { setProposedQuantity(e.target.value) }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className="mb-3" controlId="price">
                        <Form.Label column>
                            Proposed Price
                        </Form.Label>
                        <Form.Control
                            required
                            type="number"
                            value={proposedPrice}
                            disabled={disableInit}
                            onChange={(e) => { setProposedPrice(e.target.value) }}
                        />
                    </Form.Group>
                    <Form.Group as={Col} className={`mb-3 ${sellStrategy !== CRASH_SELL_STRATEGY ? "hidden" : ""}`} controlId="price">
                        <Form.Label column>
                            Proposed Percentage
                        </Form.Label>
                        <Form.Control
                            required
                            type="number"
                            value={proposedPercentage}
                            disabled={disableInit}
                            onChange={(e) => { setProposedPercentage(e.target.value) }}
                        />
                    </Form.Group>
                </Row>
                {ifShowList && <>
                    <hr />
                    <Form.Group as={Row} className="mb-3" controlId="percentage-list">
                        <Form.Label column>
                            Percentage
                        </Form.Label>
                        {percentages.map((item, index) => <Col>
                            <Form.Control
                                required
                                key={index}
                                type="number"
                                value={item}
                                disabled={disablePercentage}
                                onChange={(e) => { handleListChange(PERCENATGE_KEY, index, e.target.value) }}
                            />
                        </Col>)}
                    </Form.Group>
                    <hr />
                    <Form.Group as={Row} className="mb-3" controlId="price-list">
                        <Form.Label column>
                            Price
                        </Form.Label>
                        {prices.map((item, index) => <Col>
                            <Form.Control
                                key={index}
                                required
                                type="number"
                                value={item}
                                onChange={(e) => { handleListChange(PRICE_KEY, index, e.target.value) }}
                            />
                        </Col>)}
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="qty-list">
                        <Form.Label column>
                            Quantity
                        </Form.Label>
                        {quantities.map((item, index) => <Col>
                            <Form.Control
                                required
                                key={index}
                                type="number"
                                value={item}
                                onChange={(e) => { handleListChange(QUANTITY_KEY, index, e.target.value) }}
                            />
                        </Col>)}
                    </Form.Group>
                </>}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="ml-auto" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default WatchTemplateModal;
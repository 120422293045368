import React, { useEffect, useMemo, useState } from "react";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchEquityWatchList } from "../../Api";
import { Action, BroadIndex, colorMap, LOADING } from "../../Constants";
import { updateLoading } from "../../slices/Loading";
import DefaultColumnFilter from "../Filters/DefaultColumnFilter";
import NavigateToSignIn from "../NavigateToSignIn";
const diffSort = (x, y) => y.diff - x.diff;

function WatchPage() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const liveEquities = useSelector((state) => state.live.equities);
  const tags = useSelector((state) => state.tags.tags);
  const tagsMap = useMemo(
    () =>
      tags.reduce((state, ele) => {
        state[ele.id] = ele;
        return state;
      }, {}),
    [tags]
  );
  const [refresh, setRefresh] = useState(false);
  // redux
  const [equityWatchData, setEquityWatchData] = useState([]);
  const equityWatchDataProcessed = useMemo(() => {
    let temp = JSON.parse(JSON.stringify(equityWatchData));
    for (const key in temp) {
      let watch = temp[key];
      watch.tags = Number(watch.tags);
      if (watch.action === Action.BUY)
        watch.diff = Number((
          ((watch.price - liveEquities[watch.symbol]?.ltp) * 100) / watch.price
        ).toFixed(2));
      else if (watch.action === Action.SELL)
        watch.diff = Number((
          ((liveEquities[watch.symbol]?.ltp - watch.price) * 100) / watch.price
        ).toFixed(2));
    }
    temp.sort(diffSort);
    return temp;
  }, [equityWatchData, liveEquities]);

  useEffect(() => {
    if (user?.data?.access || refresh) {
      (async () => {
        try {
          dispatch(updateLoading(LOADING))
          let res = (await fetchEquityWatchList()).data;
          if (res) setEquityWatchData(res.watch_list);
          setRefresh(false);
        } catch (err) {
          toast.error(`Error: ${err.message}`);
        } finally {
          dispatch(updateLoading(""))
        }
      })();
    }
  }, [user, refresh, dispatch]);
  const [filterValue, setFilter] = useState("");
  const csvData = useMemo(() => {
    return equityWatchDataProcessed.map((watch) => {
      return [
        tagsMap[watch.tags]?.name,
        watch.symbol,
        BroadIndex[liveEquities[watch.symbol]?.broad_index],
        watch.action === Action.BUY ? "BUY" : "SELL",
        (Number(watch.price) * Number(watch.quantity)).toFixed(2),
        watch.quantity,
        watch.price,
        liveEquities[watch.symbol]?.ltp,
        watch.diff
      ]
    })
  }, [equityWatchDataProcessed, tagsMap, liveEquities])
  const headers = useMemo(() => {
    return ["Tag", "Symbol", "BroadIndex", "Action", "Amount", "Qty", "Target", "LTP", "Diff"]
  }, [])
  return (
    <>
      <NavigateToSignIn />
      <Row>
        <Col>
          <Row>
            <div className="flex mb-2">
              <h3 className="w-fit">FP Strategy</h3>
              <div className="w-fit ml-auto">
                <Button>
                  <CSVLink data={csvData} headers={headers} filename={"Watch.csv"} className="text-white no-underline">
                    Export to CSV
                  </CSVLink>
                </Button>
              </div>
            </div>
          </Row>
          <Row>
            <Table bordered responsive className="bg-unset">
              <thead>
                <tr>
                  <th>Tag</th>
                  <th>Symbol
                    {<DefaultColumnFilter column={{ filterValue, setFilter }} />}
                  </th>
                  <th>Action</th>
                  <th>Amount</th>
                  <th>Qty</th>
                  <th>Target</th>
                  <th>LTP</th>
                  <th>+Diff</th>
                </tr>
              </thead>
              <tbody>
                {equityWatchDataProcessed
                  ?.filter((watch) => {
                    return watch.symbol.includes(filterValue.toUpperCase())
                  })
                  ?.map((watch) => {
                    let equityColorCode = liveEquities[watch.symbol]?.color
                    let colorClass = equityColorCode ? colorMap[equityColorCode] : ""
                    return (
                      <tr key={`${watch.symbol}-${watch.tags}-${watch.action}`} >
                        <td className={colorClass}>{tagsMap[watch.tags]?.name}</td>
                        <td className={colorClass}>
                          <Link to={`/equities/${watch.symbol}`}>
                            {watch.symbol}
                          </Link>
                          <Badge className="ml-3">{BroadIndex[liveEquities[watch.symbol]?.broad_index]}</Badge>
                        </td>
                        <td className={watch.action === Action.BUY ? "force-green" : "force-yellow"}>{watch.action === Action.BUY ? "BUY" : "SELL"}</td>
                        <td className={colorClass}>{(Number(watch.price) * Number(watch.quantity)).toFixed(2)}</td>
                        <td className={colorClass}>{watch.quantity}</td>
                        <td className={colorClass}>{watch.price}</td>
                        <td className={colorClass}>{liveEquities[watch.symbol]?.ltp}</td>
                        <td className={colorClass}>{watch.diff}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default WatchPage;

import { Col, Row } from "react-bootstrap";
import NavigateToSignIn from "../NavigateToSignIn";
import FileUploadComponent from "../Upload";

function ExportPage() {
    return (
        <>
            <NavigateToSignIn />
            <h1 className="mb-8">Index Update</h1>
            <Row>
                {/* <Col>
                    <Link target='_blank' to={API_URL + "export-trades"} className="block w-fit">
                        <h1>
                            <Badge bg="warning">Trades</Badge>
                        </h1>
                    </Link>
                    <Link target='_blank' to={API_URL + "export-portfolios"} className="block w-fit">
                        <h1>
                            <Badge bg="warning">Portfolio</Badge>
                        </h1>
                    </Link>
                    <Link target='_blank' to={API_URL + "export-account-equity"} className="block w-fit">
                        <h1>
                            <Badge bg="warning">Account Equity</Badge>
                        </h1>
                    </Link>
                </Col> */}
                <Col>                    
                    <FileUploadComponent/>
                </Col>
            </Row>
        </>
    )
}

export default ExportPage;
export const dateFilterFunc = (rows, columnIds, value, addMeta) => {
    let minDate = value?.minFilterDate
    let maxDate = value?.maxFilterDate
    if (minDate && maxDate) {
        return rows.filter((row) => {
            return minDate <= row.values['executed_ts'].substring(0, 10) && row.values['executed_ts'].substring(0, 10) <= maxDate
        });
    } else if (minDate) {
        return rows.filter((row) => {
            return minDate <= row.values['executed_ts'].substring(0, 10)
        });
    } else if (maxDate) {
        return rows.filter((row) => {
            return row.values['executed_ts'].substring(0, 10) <= maxDate
        });
    }
    return rows
}

export const accountFilterFunc = (rows, columnIds, value, addMeta) => {
    return rows.filter((row) => row.values.account.display === value)
}

export const tagFilterFunc = (rows, columnIds, value, addMeta) => {
    return rows.filter((row) => row.values.tags.display === value)
}